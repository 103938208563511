import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import InputType from './InputType';
import { composeValidators, required } from '../../utils/ValidationsUtils';
import ErrorInfo from './ErrorInfo';
import { addSecondaryNumber } from '../../data/api';
import CountdownTimer from './CountdownTimer';
import './CountdownTimer.css';
import config from '../../config';

interface Props {
  reuseVerificationCode: string;
  secondaryMobileNumber: string;
}
export const VerifySecondaryNumber = ({ reuseVerificationCode, secondaryMobileNumber }: Props) => {
  const { t: translate } = useTranslation();
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '' });

  const handleSubmit = ({ verificationCode }: { verificationCode: string }) => {
    addSecondaryNumber({ verificationCode, secondaryMobileNumber: secondaryMobileNumber }).then(
      ({ status, message: errorMessage = '' }) => {
        status === 200
          ? (window.location.href = config.contextPath + '/addSecondaryNumber')
          : setErrorObj({ error: true, errorMessage });
      }
    );
  };

  useEffect(() => {
    if (reuseVerificationCode) {
      setErrorObj({ error: true, errorMessage: reuseVerificationCode });
    }
  }, [reuseVerificationCode]);

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <h1>{translate('secondaryNumber.heading')}</h1>
          <div className="hw-block--mt-medium-4 hw-block--pb">
            <div className="hw-block--mb-medium-4">
              <CountdownTimer phoneNumber={secondaryMobileNumber} flow="SecondaryNumber" />
            </div>
            <div className="hw-block hw-block--mb-small-4 hw-block--mt-medium-2">
              <span className="simple-text">{translate('passwordReset.confirmPhoneNumber')}</span>
            </div>
            <label className="hw-label hw-block--mb-small-4 hw-block--mt-small-4">
              {translate('secondaryNumber.code')}
              <Field
                type="text"
                component={InputType}
                placeholder={translate('secondaryNumber.codePlaceHolder')}
                name="verificationCode"
                validate={composeValidators(required)}
              />
            </label>
            <div className="hw-block hw-block--mt-medium-2">
              <ErrorInfo {...errorObj} />
            </div>
            <div className="hw-block hw-block--mt-medium-2">
              <div className="hw-grid">
                <div className="hw-grid__item hw-small--one-whole hw-medium--one-half hw-block--mb-smaller">
                  <button className="hw-button hw-button--primary hw-button--full">
                    {translate('secondaryNumber.moveOn')}
                  </button>
                </div>
                <div className="hw-grid__item hw-one-whole hw-medium--one-half hw-block--mt-smaller">
                  <a className="hw-link" href="/user">
                    {translate('secondaryNumber.cancel')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default VerifySecondaryNumber;
