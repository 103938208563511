const root = typeof window === 'object' ? window : global;

const config = {
  TERMS_LINK: {
    ['posten']: {
      'no': {
        prod: 'https://www.posten.no/vilkar/postenid',
        qa: 'https://www.qa.posten.no/vilkar/postenid',
      },
    },
    ['bring']: {
      'sv': {
        prod: 'https://www.bring.se/villkor/bringid',
        qa: 'https://www.qa.bring.se/villkor/bringid',
      },
      'da': {
        prod: 'https://www.bring.dk/vilkar/bringid',
        qa: 'https://www.qa.bring.dk/vilkar/bringid',
      }
    },
  },
  CONSENT_LINK: {
    ['posten']: {
      'no': {
        prod: 'https://www.posten.no/vilkar/postenid/personopplysninger-postenid',
        qa: 'https://www.qa.posten.no/vilkar/postenid/personopplysninger-postenid',
      },
    },
    ['bring']: {
      'sv': {
        prod: 'https://www.bring.se/villkor/bringid/personupplysningar-bringid',
        qa: 'https://www.qa.bring.se/villkor/bringid/personupplysningar-bringid',
      },
      'da': {
        prod: 'https://www.bring.dk/vilkar/bringid/personoplysninger-bringid',
        qa: 'https://www.qa.bring.dk/vilkar/bringid/personoplysninger-bringid',
      }
    },
  },

  lang: (root as any).lang as 'en' | 'no' | 'sv' | 'da',
  contextPath: (root as any).contextPath as string,
} as const;

export default config;
