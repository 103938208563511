import { useState } from 'react';
import { useParams } from 'react-router-dom';
import config from '../../../config';
import EnterPhoneNumberPassword from './EnterPhoneNumberPassword';
import ValidateSmsCode from './ValidateSmsCode';

export type PhoneNumberPassword = {
  phoneNumber: string;
  password: string;
};

export interface ENTER_PHONE_NUMBER_PASSWORD {
  name: 'ENTER_PHONE_NUMBER_PASSWORD';
}
export interface VALIDATE_SMS_CODE {
  name: 'VALIDATE_SMS_CODE';
  phoneNumberPassword: PhoneNumberPassword;
}
type Step = ENTER_PHONE_NUMBER_PASSWORD | VALIDATE_SMS_CODE;
export type SetStepFunction = (step: Step) => void;

export default function Login() {
  const { authUrlKey = '' } = useParams();
  const [step, setStep] = useState<Step>({ name: 'ENTER_PHONE_NUMBER_PASSWORD' });

  return (
    <div className="hw-container hw-container--slim">
      <div className="hw-block hw-block--pb-large hw-block--pt-small-3 hw-block--mb-even-larger-mobile">
        <div className="hw-grid">
          <div className="hw-grid__item hw-one-whole">
            {step.name === 'ENTER_PHONE_NUMBER_PASSWORD' && (
              <EnterPhoneNumberPassword {...step} authUrlKey={authUrlKey} setStep={setStep} />
            )}
            {step.name === 'VALIDATE_SMS_CODE' && <ValidateSmsCode {...step} authUrlKey={authUrlKey} />}
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Handle what to do after a user has been succesfully authenticated and a session created
 */
export const handlePostLogin = ({ authUrlKey }: { authUrlKey: string }) => {
  if (authUrlKey.length > 0) {
    window.location.href = config.contextPath + '/api/oauth/authorizations/authorize/' + authUrlKey;
  } else {
    window.location.href = config.contextPath + '/profile';
  }
};
