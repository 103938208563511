import React, { useId, useState } from 'react';
import { shouldShowFieldError } from '../../utils/ValidationsUtils';

interface CountryOptionProps {
  value: string;
}
const CountryOption = ({ value }: CountryOptionProps) => (
  <option data-hw-dropdown-placeholder={value} value={value}>
    {value} &nbsp;
  </option>
);

interface Props {
  input: any;
  meta: any;
  label: React.ReactNode;
  countryOptions: { value: string }[];
  onCountrySelect: (value: string) => void;
}
const PhoneInputType = ({ input, meta, label, countryOptions, onCountrySelect, ...rest }: Props) => {
  const showError = shouldShowFieldError(meta);
  const errorId = useId();

  // Make the selector a controlled input field, so that we can set the value in the onChange function below
  const [countryCode, setCountryCode] = useState(() => countryOptions[0].value);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Handle autofill with a countrycode prefix
    // E.g. +4759123456 becomes -> 59123456
    // while the country code prefix is correctly set in the country selector
    for (let { value: countryCode } of countryOptions) {
      if (e.currentTarget.value.startsWith(countryCode)) {
        e.currentTarget.value = e.currentTarget.value.slice(countryCode.length).trim();
        setCountryCode(countryCode);
        onCountrySelect(countryCode);
      }
    }

    input.onChange(e);
  };

  return (
    <div className="hw-phone-input">
      <fieldset className="hw-phone-input__label">
        <label htmlFor="phoneNumber">{label}</label>
        <div className={showError ? 'error-border hw-phone-input__group' : 'hw-phone-input__group'}>
          <select
            className="hw-dropdown--native"
            data-hw-dropdown="hw-dropdown-country-code"
            data-hw-dropdown-small={true}
            onChange={(e) => {
              setCountryCode(e.target.value);
              onCountrySelect(e.target.value);
            }}
            value={countryCode}
            name="countryCode">
            {countryOptions && countryOptions.map((option) => <CountryOption key={option.value} {...option} />)}
          </select>
          <input
            aria-invalid={showError}
            aria-describedby={errorId}
            {...input}
            {...rest}
            className="hw-input"
            type="tel"
            onChange={onChange}
          />
        </div>
      </fieldset>
      {showError && (
        <span id={errorId} className="hw-error">
          {meta.error}
        </span>
      )}
    </div>
  );
};

export default PhoneInputType;
