import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { required } from '../../../utils/ValidationsUtils';
import AccessibleH1Heading from '../../components/AccessibleH1Heading';
import CountdownTimer from '../../components/CountdownTimer';
import ErrorInfo from '../../components/ErrorInfo';
import InputType from '../../components/InputType';
import { validateSmsCode } from './api';
import type { SetStepFunction, VALIDATE_SMS_CODE } from './RegisterUser';
import registerUserAndLoginAction from './registerUserAndLoginAction';
import { ReactFinalFormWebOtpSubmitter } from '../../components/useWebOTP';

/**
 * Step 2 - Validate Sms Code
 */
type Props = VALIDATE_SMS_CODE & { setStep: SetStepFunction };
type FormValues = { verificationCode: string };
const ValidateSmsCode = ({ userDetails, setStep }: Props) => {
  const { t: translate } = useTranslation();
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '' });
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = ({ verificationCode }: FormValues) => {
    setSubmitting(true);
    validateSmsCode({ phoneNumber: userDetails.phoneNumber, verificationCode }).then((response) => {
      if (response.status !== 201) {
        setSubmitting(false);
        setErrorObj({ error: true, errorMessage: response.message });
        return;
      }
      registerUserAndLoginAction({ userDetails, verificationCode, setStep, setErrorObj, setSubmitting });
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <ReactFinalFormWebOtpSubmitter otpFieldName="verificationCode" autoSubmit={false} />
          <AccessibleH1Heading>{translate('registerUser.confirmPhonenumber')}</AccessibleH1Heading>

          <CountdownTimer phoneNumber={userDetails.phoneNumber} flow="RegisterUser" />

          <label className="hw-label hw-block--mb-small-4 hw-block--mt-small-4">
            {translate('registerUser.codeFromSms')}
            <Field
              type="text"
              component={InputType}
              validate={required}
              name="verificationCode"
              autoComplete="one-time-code"
              inputMode="numeric"
              pattern="\d{6}"
            />
          </label>

          <div className="hw-block--mt-small-4" />
          <ErrorInfo error={errorObj.error} errorMessage={errorObj.errorMessage} />
          <div className="hw-block--mt-medium-3" />

          <div className="hw-one-whole">
            <button
              type="submit"
              disabled={isSubmitting}
              className="hw-button hw-button--primary hw-button--large hw-button--full">
              {isSubmitting && (
                <span>
                  <i className="fas fa fa-spinner-third fa-spin" role="progressbar" />
                </span>
              )}
              {!isSubmitting && translate('registerUser.confirmPhonenumber')}
            </button>
          </div>

          <div className="hw-block--mt-medium-2" />

          <div className="hw-one-whole hw-text-center">
            <button type="button" className="hw-link" onClick={() => window.history.back()}>
              {translate('registerUser.cancel')}
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default ValidateSmsCode;
