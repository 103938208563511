import React, { useState } from 'react';
import UpdatePhoneNumber from '../components/UpdatePhoneNumber';
import VerifyPhoneUpdate from '../components/VerifyPhoneUpdate';

export const UpdateNumber = () => {
  const [newNumber, setNewNumber] = useState('');
  const [showSMSPage, setShowSMSPage] = useState(false);
  const [reuseVerificationCode, setReuseVerificationCode] = useState('');
  const onNumberVerified = ({
    phoneNumber,
    reuseVerificationCode = '',
  }: {
    phoneNumber: string;
    reuseVerificationCode?: string;
  }) => {
    setShowSMSPage(true);
    setNewNumber(phoneNumber);
    setReuseVerificationCode(reuseVerificationCode);
  };
  return (
    <div className="hw-container hw-container--slim">
      <div className="hw-block hw-block--pb-large hw-block--pt-large">
        <div className="hw-grid">
          <div className="hw-grid__item hw-one-whole">
            {!showSMSPage ? (
              <UpdatePhoneNumber onNumberVerified={onNumberVerified} />
            ) : (
              <VerifyPhoneUpdate reuseVerificationCode={reuseVerificationCode} newNumber={newNumber} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateNumber;
