import axios from 'axios';
import findContext from '../../../utils/FindContext';
import type { UserDetails } from './RegisterUser';
import config from "../../../config";

const headers = {
  headers: {
    'Content-Type': 'application/json',
    // @ts-ignore
    'X-CSRF-Token': window.csrfToken,
    'Accept-Language': config.lang,
  },
  method: 'POST',
};

interface RegisterUserRequest {
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  phoneNumber: string;
  verificationCode?: string;
  userConsent: '1' | '0';
}
const buildRegisterUserRequest = (userDetails: UserDetails): RegisterUserRequest => ({
  ...userDetails,
  userConsent: userDetails.userConsent ? '1' : '0',
});
export const validateUserDetails = ({ userDetails }: { userDetails: UserDetails }) =>
  axios(`${findContext()}/api/register/validate`, {
    ...headers,
    data: buildRegisterUserRequest(userDetails),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const createSmsCode = (data: { phoneNumber: string }) =>
  axios(`${findContext()}/api/verifications/phone`, {
    ...headers,
    data,
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const validateSmsCode = ({ phoneNumber, verificationCode }: { phoneNumber: string; verificationCode: string }) =>
  axios(`${findContext()}/api/verifications/phone/${phoneNumber}`, {
    ...headers,
    data: { verificationCode },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });

export const registerUserAndLogin = ({
  userDetails,
  verificationCode,
}: {
  userDetails: UserDetails;
  verificationCode: string;
}) =>
  axios(`${findContext()}/api/register/register-and-login`, {
    ...headers,
    /** @type {RegisterUserRequest} */
    data: {
      ...userDetails,
      userConsent: userDetails.userConsent ? '1' : '0',
      verificationCode,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response.data ?? error;
    });
