import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form } from 'react-final-form';

import { composeValidators, required, trimValue, validMobileNumber } from '../../../utils/ValidationsUtils';
import ErrorInfo from '../../components/ErrorInfo';
import PhoneInputType from '../../components/PhoneInputType';
import { getCountryList } from '../../../utils/country';
import { createSmsCodeForPasswordReset } from '../../../data/api';
import AccessibleH1Heading from '../../components/AccessibleH1Heading';
import type { CREATE_SMS_CODE, SetStepFunction } from './PasswordReset';

/**
 * Step 1 - Create Sms Code
 */
type Props = CREATE_SMS_CODE & { setStep: SetStepFunction };
type FormValues = {
  phoneNumber: string;
};
const CreateSmsCode = ({ setStep }: Props) => {
  const { t: translate } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState(getCountryList()[0].value);
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '' });
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = ({ phoneNumber: rawPhoneNumber }: FormValues) => {
    const phoneNumber = trimValue(selectedCountry + rawPhoneNumber);
    setSubmitting(true);
    createSmsCodeForPasswordReset({ phoneNumber }).then((response) => {
      if (response.status === 201 || response.errorCode === 'recently_sent') {
        setStep({ name: 'VALIDATE_SMS_CODE', phoneNumber });
      } else {
        setSubmitting(false);
        setErrorObj({ error: true, errorMessage: response.message });
      }
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AccessibleH1Heading>{translate('passwordReset.forgotPassword')}</AccessibleH1Heading>

          <div className="hw-block--mt-medium-3" />

          <p className="hw-text-lead hw-text-center">{translate('passwordReset.forgotPasswordText')}</p>

          <div className="hw-block--mt-medium-3" />

          <Field
            label={translate('login.mobileNumber')}
            name="phoneNumber"
            id="phoneNumber"
            component={PhoneInputType}
            countryOptions={getCountryList()}
            onCountrySelect={setSelectedCountry}
            validate={composeValidators(required, validMobileNumber)}
          />

          <div className="hw-block--mt-small-4" />
          <ErrorInfo error={errorObj.error} errorMessage={errorObj.errorMessage} />
          <div className="hw-block--mt-medium-3" />

          <div className="hw-block">
            <div className="hw-grid">
              <div className="hw-grid__item hw-small--one-whole hw-medium--one-half hw-block--mb-smaller">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="hw-button hw-button--primary hw-button--large hw-button--full">
                  {translate('passwordReset.sendSMS')}
                </button>
              </div>
              <div className="hw-grid__item hw-one-whole hw-medium--one-sixth hw-block--mt-smaller text-center-on-mobile">
                <button type="button" className="hw-link" onClick={() => window.history.back()}>
                  {translate('register.cancel')}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default CreateSmsCode;
