import React from 'react';
import './Loading.css';

interface Props {
  loading: boolean;
}
const Loading = ({ loading = false }: Props) => {
  if (loading) {
    return (
      <div className="loading">
        <div className="loading-spinner">
          <div className="hw-spinner">Laster</div>
        </div>
      </div>
    );
  }
  return null;
};

export default Loading;
