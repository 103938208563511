import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form } from 'react-final-form';

import { composeValidators, required, validateEmail } from '../../../utils/ValidationsUtils';
import ErrorInfo from '../../components/ErrorInfo';
import { validateEmailAddressForPasswordReset } from '../../../data/api';
import InputType from '../../components/InputType';
import AccessibleH1Heading from '../../components/AccessibleH1Heading';
import type { SetStepFunction, VALIDATE_EMAIL_ADDRESS } from './PasswordReset';

/**
 * Step 3 - Validate Email Address
 */
type Props = VALIDATE_EMAIL_ADDRESS & { setStep: SetStepFunction };
type FormValues = { emailAddress: string };

const ValidateEmailAddress = ({ phoneNumber, verificationCode, maskedEmailAddress, setStep }: Props) => {
  const { t: translate } = useTranslation();
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '' });
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = ({ emailAddress }: FormValues) => {
    setSubmitting(true);
    validateEmailAddressForPasswordReset({
      phoneNumber,
      verificationCode,
      emailAddress,
    }).then((response) => {
      if (response.status === 200) {
        setStep({ name: 'SET_NEW_PASSWORD', phoneNumber, verificationCode, emailAddress });
      } else {
        setSubmitting(false);
        setErrorObj({ error: true, errorMessage: response.message });
      }
    });
  };

  const onCreateNewUser = () => {
    setStep({ name: 'DELETE_USER_CREATE_NEW', phoneNumber, verificationCode, maskedEmailAddress });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AccessibleH1Heading>{translate('passwordReset.confirmEmailAddress')}</AccessibleH1Heading>

          <div className="hw-block--mt-medium-3" />

          <p className="hw-text-center">
            {translate('passwordReset.confirmEmailAddressText1')}{' '}
            <button name="createNewUser" type="button" className="hw-link" onClick={onCreateNewUser}>
              {translate('passwordReset.confirmEmailAddressText2')}
            </button>
          </p>

          <div className="hw-block--mt-medium-2" />

          <p className="hw-text-center hw-text-caption">
            {translate('passwordReset.confirmEmailAddressText3')} {maskedEmailAddress}
          </p>

          <div className="hw-block--mt-medium-3" />

          <label className="hw-label">
            {translate('register.email')}
            <Field
              type="email"
              component={InputType}
              validate={composeValidators(required, validateEmail)}
              name="emailAddress"
              // autoFocus
            />
          </label>

          <div className="hw-block--mt-small-4" />
          <ErrorInfo error={errorObj.error} errorMessage={errorObj.errorMessage} />
          <div className="hw-block--mt-medium-3" />

          <div className="hw-block">
            <div className="hw-grid">
              <div className="hw-grid__item hw-small--one-whole hw-medium--one-half hw-block--mb-smaller">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="hw-button hw-button--primary hw-button--large hw-button--full">
                  {translate('passwordReset.confirmEmailAddress')}
                </button>
              </div>
              <div className="hw-grid__item hw-one-whole hw-medium--one-half hw-block--mt-smaller text-center-on-mobile">
                <button type="button" className="hw-link" onClick={() => window.history.back()}>
                  {translate('register.cancel')}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default ValidateEmailAddress;
