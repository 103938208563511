import config from '../config';
import message from '../config/errorMessages';
import type { FieldValidator } from 'final-form';

export const removeISDCode = (fieldValue = '') => fieldValue.replace(/^\+((45)|(46)|(47))/, '');

export const validMobileNumberWithISDCode = (fieldValue = '') => {
  const value = trimValue(fieldValue);
  const regex1 = /^\+?((45)|(46)|(47))?( )?\d{8,10}$/;
  return regex1.test(value) ? undefined : message[config.lang].validMobileNumber;
};

export const validMobileNumber = (fieldValue = '') => {
  const value = trimValue(fieldValue);
  const regex1 = /^\d{8,10}$/;
  return regex1.test(value) ? undefined : message[config.lang].validMobileNumber;
};

export const mobileNumberRequired = (fieldValue = '') => {
  const value = trimValue(fieldValue);
  return value ? undefined : message[config.lang].mobileNumberRequired;
};

export const passwordRequired = (fieldValue = '') => {
  const value = trimValue(fieldValue);
  return value ? undefined : message[config.lang].passwordRequired;
};

export const required = (fieldValue: string) => {
  const value = trimValue(fieldValue);
  return value ? undefined : message[config.lang].required;
};

export const requiredWithLabel = (label: string) => (fieldValue: string) => {
  const value = trimValue(fieldValue);
  return value ? undefined : message[config.lang].requiredWithLabel(label);
};

export const firstNameLength =
  (min: number, max: number) =>
  (fieldValue = '') => {
    const value = trimValue(fieldValue);
    if (value.length < min) {
      return message[config.lang].firstNameMinLength;
    } else if (value.length > max) {
      return message[config.lang].firstNameMaxLength;
    } else {
      return undefined;
    }
  };

export const lastNameLength =
  (min: number, max: number) =>
  (fieldValue = '') => {
    const value = trimValue(fieldValue);
    if (value.length < min) {
      return message[config.lang].lastNameMinLength;
    } else if (value.length > max) {
      return message[config.lang].lastNameMaxLength;
    } else {
      return undefined;
    }
  };


export function composeValidators<T>(...validators: FieldValidator<T>[]) {
  return (value: T) =>
    validators.reduce((error, validator) => error || validator(value), undefined as string | undefined);
}

const validEmail = (fieldValue = '') => {
  const value = trimValue(fieldValue);
  if (value) {
    if (value.length > 256) {
      return false;
    }
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim;
    return regex.test(value);
  }
  return false;
};

export const validateEmail = (fieldValue = '') => {
  const value = trimValue(fieldValue);
  return value && !validEmail(value) ? message[config.lang].validateEmail : undefined;
};

export const trimValue = (value: string) => (value ? value.trim() : value);

export const mustAcceptTermsAndConditions = (value?: string) =>
  value ? undefined : message[config.lang].termsAndConditions;

/**
 * Predicate to determine when to show inline validation errors for fields
 *
 * We always show validation errors if the user has tried to submit the form
 * If the user has entered the field, typed something, and then left it, then we can also show validation errors
 *
 * If the user enters the field and then leaves, we show nothing.
 * If the user enteres the field and start typing, we show nothing,
 * because that's just annoying to get an error saying "INCORRECT EMAIL!!" while you are currently typing it
 */
export const shouldShowFieldError = ({
  invalid,
  submitFailed,
  modified,
  touched,
}: {
  invalid: boolean;
  submitFailed: boolean;
  modified: boolean;
  touched: boolean;
}) => {
  return invalid && (submitFailed || (modified && touched));
};
