import { useState } from 'react';
import PostPasswordReset from './PostPasswordReset';
import CreateSmsCode from './CreateSmsCode';
import ValidateSmsCode from './ValidateSmsCode';
import ValidateEmailAddress from './ValidateEmailAddress';
import DeleteUserCreateNew from './DeleteUserCreateNew';
import SetNewPassword from './SetNewPassword';
import { useLocation } from 'react-router-dom';

/**
 * PasswordReset page
 *
 * There a six seperate states this page can be in.
 * They are defined underneath, with their data requirements as properties
 */
export interface CREATE_SMS_CODE {
  name: 'CREATE_SMS_CODE';
}
export interface VALIDATE_SMS_CODE {
  name: 'VALIDATE_SMS_CODE';
  phoneNumber: string;
}
export interface VALIDATE_EMAIL_ADDRESS {
  name: 'VALIDATE_EMAIL_ADDRESS';
  phoneNumber: string;
  verificationCode: string;
  maskedEmailAddress: string;
}
export interface DELETE_USER_CREATE_NEW {
  name: 'DELETE_USER_CREATE_NEW';
  phoneNumber: string;
  verificationCode: string;
  maskedEmailAddress: string;
}
export interface SET_NEW_PASSWORD {
  name: 'SET_NEW_PASSWORD';
  phoneNumber: string;
  verificationCode: string;
  emailAddress: string;
}
export interface POST_PASSWORD_RESET {
  name: 'POST_PASSWORD_RESET';
}

export type Step =
  | CREATE_SMS_CODE
  | VALIDATE_SMS_CODE
  | VALIDATE_EMAIL_ADDRESS
  | DELETE_USER_CREATE_NEW
  | SET_NEW_PASSWORD
  | POST_PASSWORD_RESET;
export type SetStepFunction = (step: Step) => void;

const PasswordReset = () => {
  const authUrlKey = (useLocation().state as { authUrlKey?: string } | undefined)?.authUrlKey ?? '';

  const [step, setStep] = useState<Step>({ name: 'CREATE_SMS_CODE' });

  return (
    <div className="hw-container hw-container--slim">
      <div className="hw-block hw-block--pb-large hw-block--pt-small-3 hw-block--mb-even-larger-mobile">
        <div className="hw-grid">
          <div className="hw-grid__item hw-one-whole">
            {step.name === 'CREATE_SMS_CODE' && <CreateSmsCode {...step} setStep={setStep} />}
            {step.name === 'VALIDATE_SMS_CODE' && <ValidateSmsCode {...step} setStep={setStep} />}
            {step.name === 'VALIDATE_EMAIL_ADDRESS' && <ValidateEmailAddress {...step} setStep={setStep} />}
            {step.name === 'DELETE_USER_CREATE_NEW' && (
              <DeleteUserCreateNew {...step} setStep={setStep} authUrlKey={authUrlKey} />
            )}
            {step.name === 'SET_NEW_PASSWORD' && <SetNewPassword {...step} setStep={setStep} />}
            {step.name === 'POST_PASSWORD_RESET' && <PostPasswordReset {...step} authUrlKey={authUrlKey} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
