import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { composeValidators, required, validMobileNumber } from '../../utils/ValidationsUtils';
import { getCountryList } from '../../utils/country';
import ErrorInfo from './ErrorInfo';
import { sendSecondaryNumberForSMSVerification } from '../../data/api';
import PhoneInputType from './PhoneInputType';

interface AddSecondaryNumberProps {
  onNumberVerification: (opts: { phoneNumber: string }) => void;
}
export const AddSecondaryNumber = ({ onNumberVerification }: AddSecondaryNumberProps) => {
  const { t: translate } = useTranslation();
  const [errorObj, setError] = useState({ error: false, errorMessage: '' });
  const [landcode, setLandCode] = useState(getCountryList()[0].value);

  const handleError = ({ errorCode = '', message: errorMessage = '' }, phoneNumber: string) => {
    if (errorCode === 'recently_sent_try_again_later') {
      onNumberVerification({
        phoneNumber,
      });
    } else {
      setError({ error: true, errorMessage: errorMessage });
    }
  };

  const handleSubmit = ({ phoneNumber }: { phoneNumber: string }) => {
    sendSecondaryNumberForSMSVerification(landcode + phoneNumber).then((data) => {
      data.status === 201
        ? onNumberVerification({ phoneNumber: landcode + phoneNumber })
        : handleError(data, landcode + phoneNumber);
    });
  };

  const onCountrySelect = (landcode: string) => {
    setLandCode(landcode);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="hw-grid">
            <div className="hw-grid__item hw-block--mb">
              <a className="hw-link" href="/user">
                {translate('secondaryNumber.profileLink')}
              </a>
            </div>
          </div>
          <h1>{translate('secondaryNumber.heading')}</h1>
          <div className="hw-block--mt-medium-4 hw-block--pb">
            <div className="hw-block--mb-medium-4">
              <p className="hw-text-lead">{translate('secondaryNumber.text')}</p>
            </div>

            <Field
              label={translate('login.mobileNumber')}
              name="phoneNumber"
              id="phoneNumber"
              component={PhoneInputType}
              countryOptions={getCountryList()}
              onCountrySelect={onCountrySelect}
              validate={composeValidators(required, validMobileNumber)}
              placeholder={translate('login.mobileNumberPlaceHolder')}
            />

            <div className="hw-block--mt-medium-2">
              <ErrorInfo {...errorObj} />
            </div>
            <div className="hw-block--mt-medium-2">
              <div className="hw-grid">
                <div className="hw-grid__item hw-small--one-whole hw-medium--one-half hw-block--mb-smaller">
                  <button className="hw-button hw-button--primary hw-button--full">
                    {translate('secondaryNumber.sendSMS')}
                  </button>
                </div>
                <div className="hw-grid__item hw-one-whole hw-medium--one-half hw-block--mt-smaller">
                  <a className="hw-link" href="/user">
                    {translate('register.cancel')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default AddSecondaryNumber;
