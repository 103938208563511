import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MannTrykkerPaaMobil } from './MannTrykkerPaaMobil.svg';
import ErrorInfo from '../../components/ErrorInfo';
import { deleteUserForPasswordReset } from '../../../data/api';
import { useNavigate } from 'react-router';
import AccessibleH1Heading from '../../components/AccessibleH1Heading';
import type { DELETE_USER_CREATE_NEW, SetStepFunction } from './PasswordReset';

/**
 * Step 3.5 - Delete user, create new
 */
type Props = DELETE_USER_CREATE_NEW & { setStep: SetStepFunction; authUrlKey: string };
const DeleteUserCreateNew = ({ phoneNumber, verificationCode, maskedEmailAddress, setStep, authUrlKey }: Props) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '' });
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = () => {
    setSubmitting(true);
    deleteUserForPasswordReset({ phoneNumber, verificationCode }).then((response) => {
      if (response.status !== 200) {
        setSubmitting(false);
        setErrorObj({ error: true, errorMessage: response.message });
      } else {
        navigate('/register', { state: { authUrlKey, fromPasswordReset: { phoneNumber, verificationCode } } });
      }
    });
  };
  const onGoBack = () =>
    setStep({
      name: 'VALIDATE_EMAIL_ADDRESS',
      phoneNumber,
      verificationCode,
      maskedEmailAddress,
    });
  return (
    <div>
      <div className="hw-grid--center">
        <MannTrykkerPaaMobil />
      </div>
      <AccessibleH1Heading>{translate('passwordReset.areYouSure')}</AccessibleH1Heading>
      <div className="hw-block--mt-small-4" />
      <p className="hw-text-center">{translate('passwordReset.areYouSureText')}</p>

      <div className="hw-block--mt-small-4" />
      <ErrorInfo error={errorObj.error} errorMessage={errorObj.errorMessage} />
      <div className="hw-block--mt-medium-3" />

      <div className="hw-block">
        <div className="hw-grid">
          <div className="hw-grid__item hw-small--one-whole hw-medium--one-half hw-block--mb-smaller">
            <button
              name="createNewUser"
              type="button"
              disabled={isSubmitting}
              className="hw-button hw-button--primary hw-button--large hw-button--full"
              onClick={onSubmit}>
              {translate('passwordReset.createNewUser')}
            </button>
          </div>
          <div className="hw-grid__item hw-one-whole hw-medium--one-half hw-block--mt-smaller text-center-on-mobile">
            <button type="button" className="hw-link" onClick={onGoBack}>
              {translate('passwordReset.goBack')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserCreateNew;
