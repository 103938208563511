export const getLinksBasedOnEnv = ({ prod = '', qa = '' }, env = '') => {
  let url;
  switch (env) {
    case 'qa':
      url = qa;
      break;
    default:
      url = prod;
      break;
  }
  return url ? url : prod;
};
