import config from '../config';

export const getCountryList = () => {
  const denmarkOption = { value: '+45' };
  const swedenOption = { value: '+46' };
  const norwayOption = { value: '+47' };
  let countryOptions = [];
  if (!window.isGreenLogin) {
    countryOptions = [norwayOption, denmarkOption, swedenOption];
  } else {
    if (window.lang == 'da') {
      countryOptions = [denmarkOption, swedenOption, norwayOption];
    } else {
      countryOptions = [swedenOption, denmarkOption, norwayOption];
    }
  }
  return countryOptions;
};