import { useParams } from 'react-router-dom';
import config from '../../config';
import { acceptTermsAndConditions } from '../../data/api';
import { getConsentLink } from '../../utils/FindContext';

export const Terms = () => {
  const { authUrlKey = '' } = useParams();

  const setUserTermsAndConditions = () => {
    acceptTermsAndConditions().then((data) => {
      if (data.status === 200) {
        if (authUrlKey.length > 0) {
          window.location.href = config.contextPath + '/api/oauth/authorizations/authorize/' + authUrlKey;
        }
      }
    });
  };

  return (
    <div className="hw-container hw-container--pt hw-container--slim">
      <div className="hw-block hw-block--mt">
        <div className="hw-grid">
          <div className="hw-grid__item hw-one-whole">
            <div className="hw-block hw-block--mb">
              <div className="hw-block hw-block--mb-smaller">
                <h1 className="hw-block--pb">Nye avtalevilkår</h1>
                <p className="hw-text-lead hw-block--pb-small">
                  Vi har oppdatert vilkårene for å inkludere mulighet for innlogging med BankID.
                </p>
                <div>
                  <p>
                    Nedenfor følger vilkår («Vilkårene») som gjelder for bruk av PostenID, en tjeneste fra Posten Norge
                    AS («Posten»), org. nr. 984 661 185. Vilkårene anses som lest, forstått og akseptert av den fysiske
                    person («Brukeren») som skal bruke PostenID, ved at Brukeren fullfører registreringen og tar
                    tjenesten i bruk.
                  </p>
                  <p>
                    Vilkårene gjelder for PostenID og de tjenestene som til enhver tid er omfattet av/benytter
                    påloggingsløsningen.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">1. OM POSTENID</h2>
                  <p>
                    PostenID er en påloggingsløsning som gir Brukeren anledning til å logge på utvalgte tjenester fra
                    Posten og godkjente samarbeidspartnere med ett og samme brukernavn og passord. PostenID har den
                    hensikt å gi Brukeren kontroll med sin profil, samt anledning til å styre all elektronisk
                    kommunikasjon fra Posten relatert til de tjenester som til enhver tid benytter påloggingsløsningen.
                    Før Brukeren kan benytte PostenID, vil følgende opplysninger bli fanget i registreringsprosessen:
                  </p>
                  <ul>
                    <li>Navn</li>
                    <li>Mobiltelefonnummer</li>
                    <li>E-postadresse</li>
                  </ul>
                  <p>
                    Dersom Brukeren ønsker å benytte seg av noen av Postens tjenester som krever et høyere
                    sikkerhetsnivå kan PostenID frivillig oppgraderes ved å benytte BankID eller andre eID-løsninger
                    godkjent av Posten. I slike tilfeller vil følgende opplysninger, i tillegg til de ovennevnte, bli
                    fanget i registreringsprosessen:
                  </p>
                  <ul>
                    <li>Fødselsnummer</li>
                    <li>Bostedsadresse</li>
                  </ul>
                  <p>Brukeren må ha fylt 15 år for å kunne oppgradere PostenID med BankID.</p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">2. PRIS- OG FAKTUERINGSBESTEMMELSER</h2>
                  <p>Det er gratis å opprette PostenID.</p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">3. TJENESTENIVÅ</h2>
                  <p>
                    Det er en målsetning at PostenID skal være tilgjengelig for kunder og brukere 24 timer i døgnet, 7
                    dager i uken. Posten kan innstille tjenesten i tilfelle teknisk feil, oppgradering av programvare,
                    vedlikehold av systemet, endringer, strømbrudd eller lignende forhold.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">4. BRUKERENS BRUK AV POSTENID</h2>

                  <h3 className="hw-block--pb-smaller hw-block--pt-smaller">4.1 Generelt</h3>
                  <p>
                    Sikkerhetsløsningene er beskyttet av et passord/kode og er personlig. Brukeren er selv ansvarlig for
                    bruken. Det er derfor svært viktig at det brukes et unikt passord/kode og at det aldri oppgis eller
                    skrives ned slik at uvedkommende kan utgi seg for å være Brukeren overfor Posten. Passordet skal
                    endres i samsvar med det som til enhver tid angis som krav til sikkerhetsløsningen.
                  </p>
                  <p>
                    Brukeren kan oppgradere PostenID med BankID utstedt av hvilken som helst bank i Norge, eller annet
                    tilsvarende sikkerhetsinstrument godkjent av Posten. Passord/kode generert fra slikt
                    sikkerhetsinstrument er Brukerens signatur ved bruk av PostenID. Det vil bli lagt til grunn at det
                    er Brukeren som har benyttet seg av den enkelte tjeneste der hvor korrekt passord er benyttet.
                  </p>
                  <p>
                    Brukeren er selv ansvarlig for tap som følge av at Brukeren har gitt sin PostenID- informasjon til
                    andre eller at andre har fått slik tilgang som følge av forhold på Brukerens side.
                  </p>
                  <p>
                    Nødvendige personlige opplysninger som Posten har om Brukeren skal til enhver tid være oppdatert.
                    Brukeren er selv ansvarlig for at riktig e-postadresse og telefonnummer er lagret hos Posten og å
                    opplyse om endringer ved å logge inn på www.posten.no og endre dette under personlige innstillinger,
                    såfremt Brukeren ønsker å motta varsel per e-post og/ eller sms (avhengig av hva som tilbys) om den
                    enkelte tjeneste omfattet av PostenID.
                  </p>
                  <p>
                    Dersom Brukeren oppdager feil, mangler, uregelmessigheter eller annet som kan være av betydning for
                    Posten som leverandør av PostenID, skal Brukeren varsle Posten om dette.
                  </p>

                  <h3 className="hw-block--pb-smaller hw-block--pt-smaller">4.2 Sending av meddelelser</h3>
                  <p>
                    Posten vil sende meddelelser til Brukeren blant annet om ny funksjonalitet, driftsinformasjon og
                    annen informasjon knyttet til tjenester fra Posten og samarbeidspartnere. Brukeren gir Posten
                    tillatelse til å benytte SMS og den e-postadresse som Brukeren har oppgitt, til å rette eventuelle
                    markedsføringshenvendelser og andre henvendelser til Brukeren.
                  </p>
                  <p>
                    Brukeren kan når som helst skru av/på gjeldende varslingstjenester/henvendelser etter eget ønske
                    under “Innstillinger” eller ved å benytte avmeldingsfunksjonen direkte i evt mottatte varsling
                    (SMS/e-post) der hvor denne funksjonen gjøres tilgjengelig. Slike endringer vil kun berøre Postens
                    tjenester som benytter PostenID og ikke overstyre andre samtykker som måtte være gitt i andre
                    sammenhenger.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">5. POSTENS PLIKTER OG RETTIGHETER</h2>
                  <p>
                    Posten er ansvarlig for utføring av de oppdrag som Brukeren iverksetter i henhold til disse
                    Vilkårene.
                  </p>
                  <p>
                    Postens ansvar omfatter ikke feil, mangler eller driftsforstyrrelser knyttet til utstyr,
                    programvare, tilgang til eller overføring over Internett eller Brukerens valgte
                    autentiseringsløsning (BankID utstedt av hvilken som helst bank i Norge, eller annet tilsvarende
                    sikkerhetsinstrument godkjent av Posten).
                  </p>
                  <p>
                    Posten har anledning til å sende meddelelser til Brukeren vedrørende nye muligheter,
                    driftsinformasjon og annen informasjon knyttet til den enkelte tjeneste omfattet av PostenID.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">6. ENDRINGER</h2>
                  <p>
                    Vilkårene kan endres ved evt oppgradering til ny versjon eller med én måneds skriftlig varsel til
                    Brukeren som sendes på den e-postadresse som Brukeren har oppgitt ved registrering.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">7. TAUSHETSPLIKT</h2>
                  <p>
                    Partene plikter å ta de forholdsregler som er nødvendige for å sikre at materiale eller opplysninger
                    ikke blir brukt til andre formål enn gjennomføring av avtaleforholdet etter disse Vilkårene eller
                    gjort kjent for andre i strid med dette punkt. Ansatte eller andre som fratrer sin tjeneste hos
                    Posten skal pålegges taushet om forhold som nevnt ovenfor også etter fratredelsen.
                  </p>
                  <p>Taushetsplikten gjelder også etter at avtaleforholdet etter disse Vilkårene er opphørt.</p>
                  <p>
                    Taushetsplikten gjelder ikke når det er plikt til å gi materiale eller opplysninger i henhold til
                    lov, forskrift eller myndighetsavgjørelse.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">
                    8. BEHANDLING AV PERSONOPPLYSNINGER OG MARKEDSFØRING
                  </h2>
                  <a href={getConsentLink()} className="hw-link" target="_blank" rel="noopener noreferrer">
                    Les personvernerklæringen
                  </a>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">9. FEIL ELLER MANGLER</h2>

                  <h3 className="hw-block--pb-smaller hw-block--pt-smaller">9.1 Reklamasjon</h3>
                  <p>
                    Brukeren må gjøre Posten oppmerksom på gjeldende feil eller mangel ved PostenID eller de omfattede
                    tjenester som medfører at Brukeren ikke får levert de tjenester Brukeren har krav på etter disse
                    Vilkårene innen rimelig tid, og senest innen 10 dager etter at Brukeren oppdaget eller burde ha
                    oppdaget feilen eller mangelen.
                  </p>

                  <h3 className="hw-block--pb-smaller hw-block--pt-smaller">9.2 Avhjelp</h3>
                  <p>
                    Posten skal snarest mulig etter å ha blitt kjent med en slik feil eller mangel iverksette tiltak for
                    å rette feilen eller mangelen.
                  </p>

                  <h3 className="hw-block--pb-smaller hw-block--pt-smaller">9.3 Ansvarsbegrensning</h3>
                  <p>
                    Posten er under enhver omstendighet ikke ansvarlig for at tredjeparter eller brukere av PostenID,
                    bevisst eller ubevisst, skaffer seg adgang til Brukerens data, hindrer eller vanskeliggjør Brukerens
                    bruk av de omfattede tjenester.
                  </p>

                  <h3 className="hw-block--pb-smaller hw-block--pt-smaller">9.4 Hevning</h3>
                  <p>
                    Brukeren kan etter skriftlig varsel til Posten heve avtaleforholdet Brukeren har med Posten etter
                    disse Vilkårene med umiddelbar virkning dersom det foreligger vesentlig mislighold fra Postens side.
                    For gjennomføringen av hevingen gjelder punkt 15 annet avsnitt tilsvarende så langt den passer.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">10. BRUKERENS MISLIGHOLD</h2>

                  <h3 className="hw-block--pb-smaller hw-block--pt-smaller">10.1 Hevning og stengning</h3>
                  <p>
                    Posten kan heve avtaleforholdet Posten har med Brukeren etter disse Vilkårene umiddelbart med den
                    virkning at Brukeren ikke får tilgang til PostenID og omfattede tjenester dersom det foreligger
                    vesentlig mislighold fra Brukerens side. Som vesentlig mislighold regnes bl.a. at Brukeren har
                    oppgitt feil eller mangelfull informasjon om Brukeren, eller Brukeren bryter lov, forskrift eller
                    myndighetsavgjørelse eller på annen måte misbruker PostenID eller informasjon eller data som er en
                    del av PostenID.
                  </p>
                  <p>
                    For gjennomføringen av hevingen gjelder punkt 15 annet avsnitt tilsvarende så langt den passer,
                    likevel slik at Brukeren ikke vil ha adgang til lagrede data etter opphørstidspunktet.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">12. FRITAKELSESGRUNNER (FORCE MAJEURE)</h2>
                  <p>
                    Posten er ikke ansvarlig for tap som skyldes lovinngrep eller administrative handlinger, inntruffet
                    eller truende krig, opprør, borgerlige uroligheter, hærverk, sabotasje, terror, streik, lockout,
                    boikott og blokade, uavhengig om det er Posten selv eller Postens organisasjon konflikten er rettet
                    mot, eller iverksatt av, og uansett konfliktens årsak, herunder også når konflikten kun rammer deler
                    av Postens funksjoner, samt brudd i edb-driften (herunder svikt i leveranser fra eksterne
                    underleverandører) som skyldes ovenstående.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">
                    13. OVERDRAGELSER AV RETTIGHETER OG PLIKTER MV.
                  </h2>
                  <p>
                    Posten kan fritt overdra sine rettigheter og plikter i henhold til avtaleforholdet etter disse
                    Vilkårene mellom selskaper internt i Posten-konsernet. Rett til vederlag kan fritt overdras også
                    utenfor Posten-konsernet.
                  </p>
                  <p>
                    Dersom Brukeren har oppgradert sin PostenID ved hjelp av BankID (eller annet tilsvarende
                    sikkerhetsinstrument godkjent av Posten) kan ikke Brukeren overdra retten til å bruke PostenID siden
                    denne retten er knyttet til vedkommende personlig.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">14. VARSEL</h2>
                  <p>
                    Med mindre annet fremgår uttrykkelig av disse Vilkårene skal varsel i forbindelse med
                    avtaleforholdet etter disse Vilkårene skje skriftlig og sendes til følgende adresse:
                    <br />
                    Til Posten: kundeservice@posten.no eller
                    <br />
                    Posten Norge AS Postboks 1500 Sentrum 0001 Oslo
                    <br />
                  </p>
                  <p>
                    Til Brukeren: Kundens Digipostkasse, den e-postadresse som Brukeren har oppgitt ved registrering
                    eller den postadresse som Kunden er registrert med hos Posten.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">15. ORDINÆR OPPSIGELSE</h2>
                  <p>
                    Partene har gjensidig rett til å si opp avtaleforholdet etter disse Vilkårene med én ukes skriftlig
                    varsel, uten nærmere begrunnelse, forutsatt at alle utestående forhold er gjort opp og at partene
                    ikke har krav mot hverandre.
                  </p>
                  <p>
                    Dersom Brukeren dør, vil Brukerens PostenID og lagrede data slettes tre måneder etter Posten fikk
                    beskjed om dødsfallet. De(n) som kan forplikte Brukeren må kontakte Posten for å eventuelt få
                    tilgang til Brukerens profil innen sletting. Posten forbeholder seg retten til å beholde data som er
                    nødvendig for oppfølging av kundeforholdet.
                  </p>

                  <h2 className="hw-block--pb-smaller hw-block--pt-smaller">16. JURISDIKSJON OG VERNETING</h2>
                  <p>
                    Kontrakten er underlagt og skal tolkes i samsvar med norsk rett. Tvister mellom Posten og Brukeren
                    skal søkes løst i minnelighet. Dersom det ikke lar seg gjøre, kan hver av partene bringe tvisten inn
                    for de ordinære domstoler i samsvar med tvistelovens regler vedrørende verneting.
                  </p>
                </div>
              </div>

              <button className="hw-button hw-button--primary" onClick={() => setUserTermsAndConditions()}>
                Godta nye vilkår
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
