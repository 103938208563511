import { registerUserAndLogin } from './api';
import type { SetStepFunction, UserDetails } from './RegisterUser';

type SetErrorPayload = { error: boolean; errorMessage: string; errorCode: string };
interface Props {
  userDetails: UserDetails;
  verificationCode: string;
  setStep: SetStepFunction;
  setErrorObj: (value: SetErrorPayload) => void;
  setSubmitting: (value: boolean) => void;
}

/**
 * Action: RegisterUser and Login, before continuing to POST_REGISTER_USER
 */
const registerUserAndLoginAction = async ({
  userDetails,
  verificationCode,
  setStep,
  setSubmitting,
  setErrorObj,
}: Props) => {
  const response = await registerUserAndLogin({ userDetails, verificationCode });
  if (response.status !== 201) {
    setSubmitting(false);
    setErrorObj({ error: true, errorMessage: response.message, errorCode: response.errorCode });
    return;
  }

  setStep({ name: 'POST_REGISTER_USER' });
};

export default registerUserAndLoginAction;
