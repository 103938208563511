import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form } from 'react-final-form';

import { composeValidators, required } from '../../../utils/ValidationsUtils';
import ErrorInfo from '../../components/ErrorInfo';
import { maskedEmailId, validateSmsCodeForPasswordReset } from '../../../data/api';
import CountdownTimer from '../../components/CountdownTimer';
import InputType from '../../components/InputType';
import AccessibleH1Heading from '../../components/AccessibleH1Heading';
import type { VALIDATE_SMS_CODE, SetStepFunction } from './PasswordReset';
import { ReactFinalFormWebOtpSubmitter } from '../../components/useWebOTP';

/**
 * Step 2 - Validate Sms Code
 */
type Props = VALIDATE_SMS_CODE & { setStep: SetStepFunction };
type FormValues = { verificationCode: string };

const ValidateSmsCode = ({ phoneNumber, setStep }: Props) => {
  const { t: translate } = useTranslation();
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '' });
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = ({ verificationCode }: FormValues) => {
    // First validate the sms code
    // Then get the masked email address
    // Finally send the user to the next step, VALIDATE_EMAIL_ADDRESS
    setSubmitting(true);
    validateSmsCodeForPasswordReset({
      phoneNumber,
      verificationCode,
    }).then((validateSmsResponse) => {
      if (validateSmsResponse.status !== 200) {
        setSubmitting(false);
        setErrorObj({ error: true, errorMessage: validateSmsResponse.message });
        return;
      }
      maskedEmailId({
        phoneNumber,
        verificationCode,
      }).then((maskedEmailAddressResponse) => {
        if (maskedEmailAddressResponse.status !== 200) {
          setSubmitting(false);
          setErrorObj({ error: true, errorMessage: maskedEmailAddressResponse.message });
          return;
        }
        const { maskedEmailAddress } = maskedEmailAddressResponse.data;
        setStep({ name: 'VALIDATE_EMAIL_ADDRESS', phoneNumber, verificationCode, maskedEmailAddress });
      });
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <ReactFinalFormWebOtpSubmitter otpFieldName="verificationCode" autoSubmit />
          <AccessibleH1Heading>{translate('passwordReset.confirmPhoneNumber')}</AccessibleH1Heading>
          <CountdownTimer phoneNumber={phoneNumber} flow="PasswordReset" />
          <label className="hw-label hw-block--mb-small-4 hw-block--mt-small-4">
            {translate('passwordReset.codeFromSms')}
            <Field
              type="text"
              component={InputType}
              validate={composeValidators(required)}
              name="verificationCode"
              // autoFocus
              autoComplete="one-time-code"
              inputMode="numeric"
              pattern="\d{6}"
            />
          </label>

          <div className="hw-block--mt-small-4" />
          <ErrorInfo error={errorObj.error} errorMessage={errorObj.errorMessage} />
          <div className="hw-block--mt-medium-3" />

          <div className="hw-block">
            <div className="hw-grid">
              <div className="hw-grid__item hw-small--one-whole hw-medium--one-half hw-block--mb-smaller">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="hw-button hw-button--primary hw-button--large hw-button--full">
                  {translate('passwordReset.confirmPhoneNumber')}
                </button>
              </div>
              <div className="hw-grid__item hw-one-whole hw-medium--one-half hw-block--mt-smaller text-center-on-mobile">
                <button type="button" className="hw-link" onClick={() => window.history.back()}>
                  {translate('register.cancel')}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default ValidateSmsCode;
