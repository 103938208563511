// https://developer.chrome.com/blog/cross-device-webotp/
// https://developer.mozilla.org/en-US/docs/Web/API/WebOTP_API
import { useForm } from 'react-final-form';
import { useEffect } from 'react';

const useWebOTP = (onSuccess: (otp: { code: string }) => void, onError: (err: Error) => void) => {
  useEffect(() => {
    const abortController = new AbortController();
    if ('OTPCredential' in window) {
      (navigator.credentials as any)
        .get({
          otp: { transport: ['sms'] },
          signal: abortController.signal,
        })
        .then(onSuccess)
        .catch(onError);
    }
    return () => {
      abortController.abort();
    };
  }, []);
};

interface ReactFinalFormWebOtpSubmitterProps {
  otpFieldName: string;
  autoSubmit: boolean;
}

export const ReactFinalFormWebOtpSubmitter = ({ otpFieldName, autoSubmit }: ReactFinalFormWebOtpSubmitterProps) => {
  const form = useForm();

  useWebOTP(
    (otp) => {
      form.change(otpFieldName, otp.code);
      if (autoSubmit) {
        form.submit();
      }
    },
    (err) => console.log(err)
  );

  return null;
};
