import React, { useState } from 'react';
import AddSecondaryNumber from '../components/AddSecondaryNumber';
import VerifySecondaryNumber from '../components/VerifySecondaryNumber';

export const SecondaryNumber = () => {
  const [secondaryNumber, setSecondaryNumber] = useState('');
  const [showSMSPage, setShowSMSPage] = useState(false);
  const [reuseVerificationCode, setReuseVerificationCode] = useState('');

  const onNumberVerification = ({
    phoneNumber,
    reuseVerificationCode = '',
  }: {
    phoneNumber: string;
    reuseVerificationCode?: string;
  }) => {
    setShowSMSPage(true);
    setSecondaryNumber(phoneNumber);
    setReuseVerificationCode(reuseVerificationCode);
  };

  return (
    <div className="hw-container hw-container--slim">
      <div className="hw-block hw-block--pb-large hw-block--pt-large">
        <div className="hw-grid">
          <div className="hw-grid__item hw-one-whole">
            {!showSMSPage && <AddSecondaryNumber onNumberVerification={onNumberVerification} />}
            {showSMSPage && (
              <VerifySecondaryNumber
                reuseVerificationCode={reuseVerificationCode}
                secondaryMobileNumber={secondaryNumber}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondaryNumber;
