import { useTranslation } from 'react-i18next';
import findContext from '../../../utils/FindContext';
import SecurePostenImage from './secure_posten.png';
import SecureBringImage from './secure_bring.png';
import AccessibleH1Heading from '../../components/AccessibleH1Heading';

type Props = { authUrlKey: string };
const PostPasswordReset = ({ authUrlKey }: Props) => {
  const { t: translate } = useTranslation();

  const goToHomePage = (authUrlKey: string) => {
    if (authUrlKey.length > 0) {
      window.location.href = window.location.origin + findContext() + '/login/' + authUrlKey;
    } else {
      window.location.href = window.location.origin + findContext() + '/login';
    }
  };
  let imagePath;
  if (process.env.NODE_ENV === 'development') {
    if (!window.isGreenLogin) {
      imagePath = SecurePostenImage;
    } else {
      imagePath = SecureBringImage
    }
  } else {
    if (!window.isGreenLogin) {
      imagePath = findContext() + '/img/posten/secure_posten.png';
    } else {
      imagePath = findContext() + '/img/bring/secure_bring.png';
    }
  }

  return (
    <div className="hw-container hw-container--slim">
      <div className="hw-block hw-block--pb-large hw-block--pt-large hw-block--mb-even-larger-mobile hw-block--mb-even-larger-desktop">
        <div className="hw-grid">
          <div className="hw-grid__item hw-one-whole secure-login">
            <img className="secure-login" src={imagePath} alt="Secure login" />
            <div className="hw-block--mb-medium-1 hw-block--mt">
              <AccessibleH1Heading>{translate('postPasswordReset.heading')}</AccessibleH1Heading>
            </div>
            <div className="hw-block--mb-medium-4">
              <p className="hw-text-lead">{translate('postPasswordReset.message')}</p>
            </div>
            <div className="hw-block hw-block--mt-medium-2">
              <div className="hw-grid">
                <div className="hw-grid__item hw-small--one-whole hw-medium--one-half hw-block--mb-smaller">
                  <button
                    type="submit"
                    className="hw-button hw-button--primary hw-button--full hw-button--large"
                    onClick={() => goToHomePage(authUrlKey)}>
                    {translate('login.loginButton')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPasswordReset;
