import { forwardRef } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export const focusAfterTimeout = (ref: React.MutableRefObject<HTMLElement | null>) => {
  setTimeout(() => {
    ref.current?.focus();
  }, 100);
};

/**
 * Focus a input target in a smarter way
 * find the corresponding label and scroll to it, while focusing the input field.
 * This way the label will be visible, showing the user what the inputfield is for.
 *
 * Copied from https://github.dev/alphagov/govuk-frontend/blob/main/src/govuk/components/error-summary/error-summary.js#L164-L165
 * */
const focusTarget = (target: string) => {
  const input = document.getElementById(target);
  if (!input) return;

  const label = document.querySelector("label[for='" + input.getAttribute('id') + "']") || input.closest('label');
  if (!label) return;

  label.scrollIntoView();
  input.focus();
};

/**
 * Display after user tries to submit the form, but it has errors
 * Hide when there are no more errors.
 *
 * Used for forms with multiple inputfields where the user might not see the
 * inline validation errors on a small mobile screen
 *
 * Simple forms with one or two input fields might not need this.
 *
 * Requirements
 * - All field names must have same `id` as it's name. E.g. <Field name="email" id="email" ... />
 * - The field errors must be descriptive on their own. E.g. `Email is required` vs `Field is required`
 *
 * Principles
 * - Display at the of the page
 * - List which fields have errors, and what that error is
 * - Link to elements
 *
 * Resources:
 * - https://design-system.service.gov.uk/components/error-summary/
 * - https://aksel.nav.no/designsystem/side/errorsummary
 * - https://docs.altinn.studio/app/design/guidelines/components/error-message/
 * - https://jokul.fremtind.no/profil/skjemadesign
 *
 */
interface Props {
  title?: React.ReactNode;
}
const FormErrorSummary = forwardRef<HTMLDivElement, Props>(({ title }, ref) => {
  const { t: translate } = useTranslation();
  const { hasValidationErrors, submitFailed, errors } = useFormState();
  const shouldShowBox = hasValidationErrors && submitFailed;

  if (!shouldShowBox) return null;
  return (
    <div className="hw-warning-box" ref={ref} tabIndex={-1} role="region">
      <div className="hw-warning-box__body">
        <h3 className="hw-warning-box__title">{title ?? translate('formValidation.formContainsErrors')}</h3>
        <ul className="hw-list hw-list--no-bullets">
          {Object.entries(errors ?? {}).map(([id, message]) => (
            <li key={id}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  focusTarget(id);
                }}
                href={`#${id}`}
                className="hw-link">
                {message}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});

export default FormErrorSummary;
