import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  Form,
  json,
  LoaderFunctionArgs,
  redirect,
  useActionData,
  useLoaderData,
  useNavigation,
} from 'react-router-dom';
import { validateEmailAddressForVipps, vippsDeleteUser, vippsMaskedEmailId } from '../../../data/api';
import ErrorInfo from '../../components/ErrorInfo';

interface LoaderData {
  uniqueId: string;
  maskedEmailAddress: string;
}
export async function loader({ params }: LoaderFunctionArgs) {
  const uniqueId = params.uniqueId;
  if (!uniqueId) {
    throw new Error('expected uniqueId');
  }

  const response = await vippsMaskedEmailId(uniqueId);

  const result: LoaderData = { uniqueId, maskedEmailAddress: response.data.maskedEmailAddress };
  return json(result);
}

type ActionData = string | undefined;
export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const action = formData.get('_action') as string;
  const uniqueId = formData.get('uniqueId') as string;
  const emailAddress = formData.get('emailAddress') as string;

  if (action === 'deleteUser') {
    const response = await vippsDeleteUser(uniqueId);
    if (response.status !== 204) {
      const errorMessage: ActionData = 'Something went wrong, please try again later.';
      return json(errorMessage);
    }

    return redirect(`/vipps/user/${uniqueId}/termsAndConditionsLink`);
  }

  const response = await validateEmailAddressForVipps({ uniqueId, emailAddress });
  if (response.status === 200) {
    window.sessionStorage.setItem('emailAddress', emailAddress);
    return redirect(`/vipps/user/${uniqueId}/updateUser`);
  }

  const errorMessage: ActionData = response.message;
  return json(errorMessage);
}

export function Component() {
  const { maskedEmailAddress, uniqueId } = useLoaderData() as LoaderData;
  const navigation = useNavigation();
  const { t: translate } = useTranslation();
  const errorMessage = useActionData() as ActionData;

  return (
    <div className="hw-container hw-container--slim">
      <div className="hw-block hw-block--pb-large hw-block--pt-small-3 hw-block--mb-even-larger-mobile">
        <div className="hw-grid">
          <div className="hw-grid__item hw-one-whole">
            <Form method="post">
              <h1>{translate('vippsOrPostenUserDetails.validateHeading')}</h1>

              <div className="hw-block--mt-medium-3" />

              <input type="submit" name="_action" value="submit" hidden />
              <input name="uniqueId" value={uniqueId} hidden readOnly />

              <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                <p>{translate('vippsOrPostenUserDetails.message1')}</p>
                <p>
                  {translate('vippsOrPostenUserDetails.message2')}{' '}
                  <button name="_action" value="deleteUser" type="submit" className="hw-link">
                    {translate('vippsOrPostenUserDetails.createNewUser')}
                  </button>
                </p>
                <p className="hw-text-technical">
                  {translate('vippsOrPostenUserDetails.emailHint')} {maskedEmailAddress}
                </p>
              </div>

              <div className="hw-block--mt-medium-3" />

              <label className="hw-label">
                {translate('vippsOrPostenUserDetails.email')}
                <input type="email" name="emailAddress" className="hw-input" />
              </label>

              <div className="hw-block--mt-small-4" />
              <ErrorInfo error={Boolean(errorMessage)} errorMessage={errorMessage!} />
              <div className="hw-block--mt-small-3" />

              <div className="hw-block--mt-medium-3" />

              <button
                type="submit"
                name="_action"
                value="submit"
                disabled={navigation.state === 'submitting'}
                className="hw-button hw-button--primary hw-button--full hw-button--medium">
                {translate('vippsOrPostenUserDetails.confirmEmailAddress')}
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
