import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import { composeValidators, required, validMobileNumber } from '../../utils/ValidationsUtils';
import { getCountryList } from '../../utils/country';
import ErrorInfo from './ErrorInfo';
import { sendPhoneNumberForSMSVerification } from '../../data/api';
import PhoneInputType from './PhoneInputType';
import { useTranslation } from 'react-i18next';

interface Props {
  onNumberVerified: (opts: { phoneNumber: string }) => void;
}
export const UpdatePhoneNumber = ({ onNumberVerified }: Props) => {
  const { t: translate } = useTranslation();

  const [errorObj, setError] = useState({
    error: false,
    errorMessage: '',
    phoneNumberInUse: false,
  });
  const [countryCode, setCountryCode] = useState(getCountryList()[0].value);

  const handleError = ({ errorCode = '', message: errorMessage = '' }, phoneNumber: string) => {
    if (errorCode === 'recently_sent_try_again_later') {
      onNumberVerified({
        phoneNumber: phoneNumber,
      });
    } else {
      const phoneNumberInUse = errorCode === 'phone_number_in_use';
      setError({ error: true, errorMessage, phoneNumberInUse });
    }
  };
  const handleSubmit = ({ phoneNumber }: { phoneNumber: string }) => {
    sendPhoneNumberForSMSVerification({
      phoneNumber: countryCode + phoneNumber,
    }).then((data) => {
      data.status === 201
        ? onNumberVerified({ phoneNumber: countryCode + phoneNumber })
        : handleError(data, countryCode + phoneNumber);
    });
  };

  const onCountrySelect = (countryCode: string) => {
    setCountryCode(countryCode);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="hw-grid">
            <div className="hw-grid__item hw-block--mb">
              <a className="hw-link" onClick={() => window.history.back()}>
                {translate('secondaryNumber.profileLink')}
              </a>
            </div>
          </div>
          <h1>{translate('update.heading')}</h1>
          <div className="hw-block--mt-medium-4 hw-block--pb">
            <div className="hw-block--mb-medium-4">
              <p className="hw-text-lead">{translate('update.text')}</p>
            </div>
            <Field
              label={translate('login.mobileNumber')}
              name="phoneNumber"
              id="phoneNumber"
              component={PhoneInputType}
              countryOptions={getCountryList()}
              onCountrySelect={onCountrySelect}
              validate={composeValidators(required, validMobileNumber)}
              placeholder={translate('login.mobileNumberPlaceHolder')}
            />
            <div className="hw-block--mt-medium-2">
              <ErrorInfo {...errorObj} />
            </div>
            <div className="hw-block hw-block--mt-medium-2">
              <div className="hw-grid">
                <div className="hw-grid__item hw-small--one-whole hw-medium--one-half hw-block--mb-smaller">
                  <button className="hw-button hw-button--primary hw-button--full">
                    {translate('update.sendSMS')}
                  </button>
                </div>
                <div className="hw-grid__item hw-one-whole hw-medium--one-half hw-block--mt-smaller">
                  <a className="hw-link" onClick={() => window.history.back()}>
                    {translate('register.cancel')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default UpdatePhoneNumber;
