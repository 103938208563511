import { useEffect, useRef } from 'react';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  children: React.ReactNode;
}

/**
 * h1 heading with accessiblity prompt
 * On mount it focuses the h1 tag which causes screen readers to read it out load
 *
 * See:
 * https://bbc.github.io/gel/foundations/routing/#focus-management
 * https://accessible-app.com/pattern/vue/routing
 */
const AccessibleH1Heading = ({ children, ...rest }: Props) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    ref.current?.focus();
  }, []);
  return (
    <h1 {...rest} tabIndex={-1} ref={ref}>
      {children}
    </h1>
  );
};

export default AccessibleH1Heading;
