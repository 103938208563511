import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getVippsInfo, registerVippsUser } from '../../data/api';
import { useTranslation } from 'react-i18next';
import { getTermsLink } from '../../utils/FindContext';
import ErrorInfo from '../components/ErrorInfo';
import errorMessages from '../../config/errorMessages';
import config from '../../config';

export const TermsAndConditions = () => {
  const { t: translate } = useTranslation();
  const [oauthUrlKey, setOAuthUrlKey] = useState('');
  const [vippsUser, setVippsUserInfo] = useState({
    vippsUserFirstName: '',
    vippsUserLastName: '',
    vippsUserEmailAddress: '',
    phoneNumber: '',
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '' });
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const { uniqueId } = useParams() as { uniqueId: string };

  const handleSubmit = () => {
    if (!termsAndConditions) {
      setErrorObj({
        error: true,
        errorMessage: errorMessages[config.lang].termsAndConditions,
      });
      return;
    }
    setSubmitting(true);
    setErrorObj({ error: false, errorMessage: '' });
    registerVippsUser(uniqueId).then((data) => {
      if (data.status === 200) {
        if (oauthUrlKey.length > 0) {
          window.location.href = config.contextPath + '/api/oauth/authorizations/authorize/' + oauthUrlKey;
        } else {
          window.location.href = config.contextPath + '/profile';
        }
      } else {
        setSubmitting(false);
        setErrorObj({ error: true, errorMessage: data.message });
      }
    });
  };

  const setValue: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.checked) {
      setErrorObj({
        error: true,
        errorMessage: errorMessages[config.lang].termsAndConditions,
      });
      setTermsAndConditions(false);
    } else {
      setErrorObj({ error: false, errorMessage: '' });
      setTermsAndConditions(true);
    }
  };

  useEffect(() => {
    getVippsInfo(uniqueId).then((response) => {
      if (response.status === 200) {
        const {
          vippsUserFirstName = '',
          vippsUserLastName = '',
          vippsUserEmailAddress = '',
          oauthUrlKey = '',
          phoneNumber = '',
        } = response.data;
        setVippsUserInfo({
          vippsUserFirstName,
          vippsUserLastName,
          vippsUserEmailAddress,
          phoneNumber,
        });
        setOAuthUrlKey(oauthUrlKey);
      }
    });
  }, []);

  return (
    <div className="hw-container hw-container--slim">
      <div className="hw-block hw-block--pb-large hw-block--pt-large">
        <div className="hw-grid">
          <div className="hw-grid__item hw-one-whole">
            <h1>{translate('register.vippsTerms')}</h1>
            <div className="hw-block--mt-medium-4">
              <div className="hw-block--mb-medium-4">
                <p className="hw-text-lead">{translate('login.infoToUseForLogin')}</p>
              </div>
              <div className="hw-grid">
                <div className="hw-grid__item hw-one-whole">
                  {translate('login.name')}
                  <span className="hw-radio-button__label">
                    {vippsUser.vippsUserFirstName} {vippsUser.vippsUserLastName}
                  </span>
                  <br />
                  {translate('login.emailAddress')}
                  <span>{vippsUser.vippsUserEmailAddress}</span>
                  <br />
                  {translate('login.phoneNumber')}
                  <span>{vippsUser.phoneNumber}</span>
                </div>
              </div>
            </div>
            <div className="hw-block--mt-medium-2">
              <label className="hw-checkbox">
                <p>
                  {translate('register.accept')}
                  <a target="_blank" href={getTermsLink()} rel="noopener noreferrer" className="hw-link">
                    {translate('register.terms')}
                  </a>{' '}
                  {translate('register.conditions')}
                </p>
                <input type="checkbox" name="acceptTerms" onChange={setValue} />
                <i className="hw-checkbox__indicator"></i>
              </label>
            </div>
            <div className="hw-block--mt-medium-2">
              <ErrorInfo error={errorObj.error} errorMessage={errorObj.errorMessage} />
            </div>
            <div className="hw-block hw-block--mt-medium-2">
              <div className="hw-grid">
                <div className="hw-grid__item hw-small--one-whole hw-medium--one-half hw-block--mb-smaller">
                  <button
                    disabled={isSubmitting}
                    className="hw-button hw-button--primary hw-button--full"
                    onClick={handleSubmit}>
                    {translate('login.confirm')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
