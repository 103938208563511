import { useId, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import style from './PasswordInputField.module.css';
import { shouldShowFieldError } from '../../../utils/ValidationsUtils';
import { useTranslation } from 'react-i18next';

interface Classes {
  input?: string;
  root?: string;
  showHide?: string;
  error?: string;
}

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  input: any;
  meta: any;
  classes?: Classes;
}
const PasswordInputField = ({ input, meta, classes, ...rest }: Props) => {
  const { t: translate } = useTranslation();

  const [isPasswordShownInner, setPasswordShown] = useState<'initialFalse' | true | false>(
    // We use the isPasswordShown state to control aria-live tag.
    // We only want the it to be announced when user clicks the show/hide button
    // not on initial mount
    'initialFalse'
  );
  const isPasswordShown = isPasswordShownInner === true;

  const showError = shouldShowFieldError(meta);
  const className = `hw-input ${showError ? 'hw-input--error' : ''} ${style.input} ${classes?.input ?? ''}`;
  const errorId = useId();
  const inputRef = useRef<HTMLElement>();

  const onPasswordShowHideClick = () => {
    setPasswordShown(!isPasswordShown);
    inputRef.current?.focus();
  };
  return (
    <>
      <div className={`${style.inputWrapper} ${classes?.root ?? ''}`}>
        <input
          {...input}
          {...rest}
          ref={inputRef}
          type={isPasswordShown ? 'text' : 'password'}
          className={className}
          aria-invalid={showError}
          aria-describedby={`${errorId} ${rest['aria-describedby'] ?? ''}`}
        />
        <button
          aria-label={
            isPasswordShown ? translate('passwordInput.hidePassword') : translate('passwordInput.showPassword')
          }
          type="button"
          className={`${style.showHidePasswordButton} ${classes?.showHide ?? ''}`}
          onClick={onPasswordShowHideClick}>
          <FontAwesomeIcon icon={isPasswordShown ? faEye : faEyeSlash} />
        </button>
        <p aria-live={isPasswordShownInner === 'initialFalse' ? 'off' : 'polite'} className="sr-only">
          {isPasswordShown ? translate('passwordInput.passwordShown') : translate('passwordInput.passwordHidden')}
        </p>
      </div>
      {showError && (
        <span id={errorId} className={`hw-error ${style.error} ${classes?.error ?? ''}`}>
          {meta.error}
        </span>
      )}
    </>
  );
};

export default PasswordInputField;
