import React, { useId } from 'react';
import { shouldShowFieldError } from '../../utils/ValidationsUtils';

interface Props {
  input: any;
  meta: any;
  label: React.ReactNode;
  className?: string;
}
const CheckboxInputType = ({ input, meta, label, className = '', ...rest }: Props) => {
  const showError = shouldShowFieldError(meta);
  const errorId = useId();
  return (
    <>
      <label className={`hw-checkbox hw-checkbox--full ${showError ? 'hw-checkbox--bounding hw-checkbox--error' : ''}`}>
        {label}
        <input
          aria-invalid={showError}
          aria-describedby={errorId}
          {...input}
          {...rest}
          type="checkbox"
          className={`hw-input ${showError ? 'hw-input--error' : ''} ${className}`}
        />
        <i className="hw-checkbox__indicator"></i>
        {showError && (
          <span id={errorId} className="hw-error-simple hw-error--align-left">
            {meta.error}
          </span>
        )}
      </label>
    </>
  );
};

export default CheckboxInputType;
