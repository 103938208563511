import { useTranslation } from 'react-i18next';
import type { TFunction, TFuncKey } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import PasswordInputField from './PasswordInputField';
import style from './SetPasswordInputField.module.css';

interface PasswordRequirement {
  name: TFuncKey<'translations', undefined>;
  validate: (password: string) => boolean;
}

const passwordRequirements: PasswordRequirement[] = [
  {
    name: 'setPassword.atLeastOneLargeLetter',
    validate: (password) => /[A-Z]/.test(password) || /[ÆØÅÖÄ]/.test(password),
  },
  {
    name: 'setPassword.atLeastOneSmallLetter',
    validate: (password) => /[a-z]/.test(password) || /[æøåöä]/.test(password),
  },
  { name: 'setPassword.atLeastOneNumber', validate: (password) => /\d/.test(password) },
  { name: 'setPassword.atLeast8Characters', validate: (password) => password.length >= 8 },
];
const processPassword = (password: string) =>
  passwordRequirements.map(({ name, validate }) => ({ name, isValid: validate(password) }));

export const validatePassword = (translate: TFunction) => (password: string) =>
  passwordRequirements.every((x) => x.validate(password)) ? undefined : translate('setPassword.invalidPassword');

type Props = React.ComponentProps<typeof PasswordInputField>;
const SetPasswordInputField = ({ input, meta, ...rest }: Props) => {
  const { t: translate } = useTranslation();
  const passwordResults = processPassword(input.value);
  return (
    <div className={style.root}>
      <PasswordInputField
        input={input}
        meta={meta}
        autoComplete="new-password"
        // Make the password requirements be announced when the user has selected the input field
        aria-describedby="password-requirements"
        classes={{ error: style.inputErrorNotice }}
        {...rest}
      />
      <div className="hw-block--mt-small-4" id="password-requirements">
        <p className="hw-text-technical-title">{translate('setPassword.passwordRequirements')}</p>
        <div className="hw-block--mb-small-2" />
        <p className="sr-only">. {passwordRequirements.map(({ name }) => translate(name)).join('. ')}</p>
        <ul className={`fa-ul ${style.list}`} aria-hidden="true">
          {passwordResults.map(({ name, isValid }) => (
            <li key={name} className={isValid ? style.valid : ''}>
              <>
                {isValid && <FontAwesomeIcon icon={faCircleCheck} listItem />}
                {!isValid && <span className="fa-li">•</span>}
                {translate(name)}
              </>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SetPasswordInputField;
