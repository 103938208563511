import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import config from '../../config';
import Loading from '../components/Loading';

const Level4AuthenticationPageCallback = () => {
  const { authUrlKey = '', status = '' } = useParams();

  useEffect(() => {
    if (authUrlKey.length > 0 && status === 'complete') {
      window.location.href = config.contextPath + '/api/oauth/authorizations/authorize/' + authUrlKey;
    }
  }, []);

  return status === 'complete' ? <Loading loading={true} /> : null;
};

export default Level4AuthenticationPageCallback;
