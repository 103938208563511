/** @jsxRuntime classic */
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './config/i18n';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import Level4AuthenticationPageCallback from './app/pages/Level4AuthenticationPageCallback';
import RegisterUser from './app/pages/RegisterUser/RegisterUser';
import EmailVerificationStatus from './app/pages/EmailVerificationStatus';
import Terms from './app/pages/Terms';
import findContext from './utils/FindContext';
import PasswordReset from './app/pages/PasswordReset/PasswordReset';
import UpdateNumber from './app/pages/UpdateNumber';
import Level4AuthenticationPage from './app/pages/Level4AuthenticationPage';
import TermsAndConditions from './app/pages/TermsAndConditions';
import SecondaryNumber from './app/pages/SecondaryNumber';
import { loadScript } from './services/decorator';
import { getDecorator } from './data/api';
import Login from './app/pages/Login/Login';
import * as ChooseVippsOrPostenUserDetailsRoute from './app/pages/ChooseVippsOrPostenUserDetails/ChooseVippsOrPostenUserDetails';
import * as VippsValidateEmailAddressRoute from './app/pages/ChooseVippsOrPostenUserDetails/ValidateEmailAddress';
import { useTranslation } from 'react-i18next';

/**
 * For apps, we don't bother showing the header and footer for Posten
 */
const isInAppView = new URLSearchParams(window.location.search).get('is_app') === 'true';
let decoratorPromise: Promise<string>;
if (isInAppView) {
  decoratorPromise = Promise.resolve('');
} else {
  decoratorPromise = getDecorator();
}

/**
 * For local development, ensures that the browser is navigated to the base context
 * much nicer than getting a blank page, forcing the developer to manually type in /id in the url
 *
 * In PROD the context path is handled automaticly by the infrastructure
 */
if (window.location.href.indexOf(findContext()) === -1) {
  window.location.assign(findContext());
}

decoratorPromise.then((decorator) => {
  const decoratorHTML = new DOMParser().parseFromString(decorator, 'text/html');
  const searchField = decoratorHTML.getElementById('search-reactjs') as HTMLScriptElement | undefined;
  const layout = document.getElementById('application');
  if (!layout) throw new Error('could not get layout element with id: application');

  if (decoratorHTML) {
    let header = decoratorHTML.getElementsByTagName('header')[0];
    if (header) {
      layout.appendChild(header);
    }
  }
  layout.innerHTML += '<main id="root"><!--react-placeholder--></main>';
  if (decoratorHTML) {
    let footer = decoratorHTML.getElementsByTagName('footer')[0];
    if (footer) {
      layout.appendChild(footer);
    }
  }

  const root = createRoot(document.getElementById('root')!);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        {['/login/:authUrlKey', '/login', '/', '/profile'].map((path) => (
          <Route key={path} path={path} element={<Login />} />
        ))}
          {['/register/:authUrlKey', '/register'].map((path) => (
              <Route key={path} path={path} element={<RegisterUser />} />
          ))}
        <Route path="/password/reset" element={<PasswordReset />} />
        <Route path="/updateNumber" element={<UpdateNumber />} />
        <Route path="/profile/level4/:authUrlKey/:status" element={<Level4AuthenticationPageCallback />} />
        <Route path="/profile/level4/:authUrlKey" element={<Level4AuthenticationPage />} />
        <Route
          path="/vipps/user/:uniqueId/validateEmailAddress"
          element={<VippsValidateEmailAddressRoute.Component />}
          loader={VippsValidateEmailAddressRoute.loader}
          shouldRevalidate={() => false}
          action={VippsValidateEmailAddressRoute.action}
        />
        <Route
          path="/vipps/user/:uniqueId/updateUser"
          element={<ChooseVippsOrPostenUserDetailsRoute.Component />}
          loader={ChooseVippsOrPostenUserDetailsRoute.loader}
          shouldRevalidate={() => false}
          action={ChooseVippsOrPostenUserDetailsRoute.action}
        />
        <Route path="/vipps/user/:uniqueId/termsAndConditionsLink" element={<TermsAndConditions />} />
        <Route path="/terms/:authUrlKey" element={<Terms />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/email-verification/:verificationStatus" element={<EmailVerificationStatus />} />
        <Route path="/secondaryNumber" element={<SecondaryNumber />} />
      </>
    ),
    { basename: findContext() }
  );

  root.render(
    <App>
      {/* Iso date, offested to norwegian time (+2 or +1 depending on DST)*/}
      <MaintenanceBanner
        startDateTimeIso="2024-08-18T20:45:00+02:00"
        endDateTimeIso="2024-08-19T05:00:00+02:00"
        textKey={'maintenance.2024-08-18-sms-maintenance'}
      />

      {/* Since the header is gone in app views, add some whitespace */}
      {isInAppView && <div className="hw-block--mt-medium-2" />}
      {window.isGreenLogin && <div className="hw-block--mt-medium-2" />}
      <RouterProvider router={router} />
    </App>
  );

  loadScript('https://cdn.jsdelivr.net/npm/@posten/hedwig@11/dist/main.js', 'hw-js');

  if (searchField) {
    loadScript(searchField.src, searchField.id);
  }
});

// Maintenance banner
interface MaintenanceBannerProps {
  textKey: Parameters<ReturnType<typeof useTranslation>['t']>;
  startDateTimeIso: string;
  endDateTimeIso: string;
}
function MaintenanceBanner({ textKey, startDateTimeIso, endDateTimeIso }: MaintenanceBannerProps) {
  const { t: translate } = useTranslation();

  // Only show maintenance banner inside the given time frame
  const startTime = new Date(startDateTimeIso);
  const endTime = new Date(endDateTimeIso);
  const now = new Date();
  if (now < startTime || now > endTime) {
    return null;
  }

  return (
    <div className="hw-warning-banner" style={{ display: 'flex', justifyContent: 'space-around' }}>
      <div className="hw-warning-banner__trigger" style={{ maxWidth: 720 }}>
        {translate(textKey)}
      </div>
    </div>
  );
}
