import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Field, Form } from 'react-final-form';

import { composeValidators, required } from '../../../utils/ValidationsUtils';
import ErrorInfo from '../../components/ErrorInfo';
import { resetPassword } from '../../../data/api';
import AccessibleH1Heading from '../../components/AccessibleH1Heading';
import SetPasswordInputField, { validatePassword } from '../../components/PasswordInput/SetPasswordInputField';
import type { SetStepFunction, SET_NEW_PASSWORD } from './PasswordReset';

/**
 * Step 4 - Set new password
 */
type Props = SET_NEW_PASSWORD & { setStep: SetStepFunction };
type FormValues = { newPassword: string };

const SetNewPassword = ({ phoneNumber, verificationCode, emailAddress, setStep }: Props) => {
  const { t: translate } = useTranslation();
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '' });
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = ({ newPassword }: FormValues) => {
    setSubmitting(true);
    resetPassword({ phoneNumber, verificationCode, emailAddress, newPassword }, phoneNumber).then((response) => {
      if (response.status !== 200) {
        setSubmitting(false);
        setErrorObj({ error: true, errorMessage: response.message });
      } else {
        setStep({ name: 'POST_PASSWORD_RESET' });
      }
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <AccessibleH1Heading>{translate('passwordReset.setNewPassword')}</AccessibleH1Heading>

          <div className="hw-block--mt-medium-2" />

          <label className="hw-label" htmlFor="newPassword">
            {translate('passwordReset.newPassword')}
          </label>
          <Field
            component={SetPasswordInputField}
            validate={composeValidators(required, validatePassword(translate))}
            name="newPassword"
            id="newPassword"
          />

          <div className="hw-block--mt-small-4" />
          <ErrorInfo error={errorObj.error} errorMessage={errorObj.errorMessage} />
          <div className="hw-block--mt-medium-3" />

          <div className="hw-block">
            <div className="hw-grid">
              <div className="hw-grid__item hw-small--one-whole hw-medium--one-half hw-block--mb-smaller">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="hw-button hw-button--primary hw-button--large hw-button--full">
                  {isSubmitting && (
                    <span>
                      <i className="fas fa fa-spinner-third fa-spin" role="progressbar" />
                    </span>
                  )}
                  {!isSubmitting && translate('passwordReset.setNewPassword')}
                </button>
              </div>
              <div className="hw-grid__item hw-one-whole hw-medium--one-half hw-block--mt-smaller text-center-on-mobile">
                <button type="button" className="hw-link" onClick={() => window.history.back()}>
                  {translate('register.cancel')}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default SetNewPassword;
