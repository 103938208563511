import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type VerificationStatus = 'email_verified' | 'email_verification_not_found' | 'email_verification_expired' | '';

export const EmailVerificationStatus = () => {
  const { t: translate } = useTranslation();
  const { verificationStatus } = useParams<{ verificationStatus: VerificationStatus }>();

  const renderSwitch = (param: VerificationStatus) => {
    switch (param) {
      case 'email_verified':
        return (
          <React.Fragment>
            <h1 className="hw-block--pb">{translate('email.verifiedHeading')}</h1>
            <p className="hw-text-lead hw-block--pb-small">{translate('email.verifiedText')}</p>
          </React.Fragment>
        );
      case 'email_verification_not_found':
        return (
          <React.Fragment>
            <h1 className="hw-block--pb">{translate('email.verificationNotFoundHeading')}</h1>
            <p className="hw-text-lead hw-block--pb-small">{translate('email.verificationNotFoundText')}</p>
          </React.Fragment>
        );
      case 'email_verification_expired':
        return (
          <React.Fragment>
            <h1 className="hw-block--pb">{translate('email.verificationExpiredHeading')}</h1>
            <p className="hw-text-lead hw-block--pb-small">{translate('email.verificationExpiredText')}</p>
          </React.Fragment>
        );
      default:
        // TODO: We should: throw new Error("")
        // https://dev.to/ddiprose/exhaustive-switch-statement-with-typescript-26dh
        return null;
    }
  };

  return (
    <div className="hw-container hw-container--slim">
      <div className="hw-block hw-block--pb-large hw-block--pt-large hw-block--mt-small">
        <div className="hw-grid">
          <div className="hw-grid__item hw-one-whole">{renderSwitch(verificationStatus as VerificationStatus)}</div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationStatus;
