import React from 'react';
import { useState, useEffect } from 'react';
import {
  createSmsCodeForPasswordReset,
  sendPhoneNumberForSMSVerification,
  sendSecondaryNumberForSMSVerification,
  validatePhoneNumberPasswordAndCreateSmsCode,
} from '../../data/api';
import { useTranslation } from 'react-i18next';
import { ErrorInfo } from './ErrorInfo';
import './CountdownTimer.css';

type Props =
  | {
      phoneNumber: string;
      password?: never;
      flow: 'RegisterUser' | 'UpdatePhoneNumber' | 'SecondaryNumber' | 'PasswordReset';
    }
  | {
      phoneNumber: string;
      password: string;
      flow: 'LoginUser';
    };
const CountdownTimer = ({ phoneNumber, password, flow }: Props) => {
  const { t: translate } = useTranslation();
  const [timer, setTimer] = useState({ minutes: 0, seconds: 0 });
  const [time] = useState(() => new Date());
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '' });
  const [showResendLink, setShowResendLink] = useState(true);
  const [sendNewCode, setSendNewCode] = useState(false);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timer.seconds > 0) {
        setTimer({ minutes: timer.minutes, seconds: timer.seconds - 1 });
      }
      if (timer.seconds === 0) {
        if (timer.minutes === 0) {
          setShowResendLink(true);
          clearInterval(myInterval);
        } else {
          setTimer({ minutes: timer.minutes - 1, seconds: 59 });
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const createVerificationCodeForUser = () => {
    setErrorObj({ error: false, errorMessage: '' });
    createSmsCodeForPasswordReset({ phoneNumber: phoneNumber }).then((data) => {
      if (data.status === 201) {
        setTimer({ minutes: 5, seconds: 0 });
        setShowResendLink(false);
        setSendNewCode(true);
      } else if (data.errorCode === 'recently_sent') {
        setCountdown();
        setShowResendLink(false);
        setSendNewCode(false);
      } else {
        setShowResendLink(true);
        setErrorObj({ error: true, errorMessage: data.message });
      }
    });
  };

  const setCountdown = () => {
    const millis = Number(new Date()) - Number(time);
    const minutes = 4 - Math.floor((millis / 1000 / 60) % 60);
    const seconds = 60 - Math.floor((millis / 1000) % 60);
    setTimer({ minutes: minutes, seconds: seconds });
  };

  const sendPhoneNumberForSMSVerificationForUser = () => {
    setErrorObj({ error: false, errorMessage: '' });
    sendPhoneNumberForSMSVerification({ phoneNumber: phoneNumber }).then((data) => {
      if (data.status === 201) {
        setTimer({ minutes: 5, seconds: 0 });
        setShowResendLink(false);
        setSendNewCode(true);
      } else if (data.errorCode === 'recently_sent_try_again_later') {
        setCountdown();
        setShowResendLink(false);
        setSendNewCode(false);
      } else {
        setShowResendLink(true);
        setErrorObj({ error: true, errorMessage: data.message });
      }
    });
  };

  const sendSecondaryNumberForSMSVerificationForUser = () => {
    setErrorObj({ error: false, errorMessage: '' });
    sendSecondaryNumberForSMSVerification(phoneNumber).then((data) => {
      if (data.status === 201) {
        setTimer({ minutes: 5, seconds: 0 });
        setShowResendLink(false);
        setSendNewCode(true);
      } else if (data.errorCode === 'recently_sent_try_again_later') {
        setCountdown();
        setShowResendLink(false);
        setSendNewCode(false);
      } else {
        setShowResendLink(true);
        setErrorObj({ error: true, errorMessage: data.message });
      }
    });
  };

  const sendLoginSMSVerificationForUser = () => {
    setErrorObj({ error: false, errorMessage: '' });
    validatePhoneNumberPasswordAndCreateSmsCode({ phoneNumber, password: password! }).then((data) => {
      if (data.status === 201) {
        setTimer({ minutes: 5, seconds: 0 });
        setShowResendLink(false);
        setSendNewCode(true);
      } else if (data.errorCode === 'recently_sent_try_again_later') {
        setCountdown();
        setShowResendLink(false);
        setSendNewCode(false);
      } else {
        setShowResendLink(true);
        setErrorObj({ error: true, errorMessage: data.message });
      }
    });
  };

  const sendVerificationCode = () => {
    if ('LoginUser' === flow) {
      sendLoginSMSVerificationForUser();
    } else if ('RegisterUser' === flow || 'UpdatePhoneNumber' === flow) {
      sendPhoneNumberForSMSVerificationForUser();
    } else if ('PasswordReset' === flow) {
      createVerificationCodeForUser();
    } else {
      sendSecondaryNumberForSMSVerificationForUser();
    }
  };

  const formatPhoneNumber = (str: string) => {
    const cleaned = ('' + str).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{2})(\d{2})(\d{2})(\d{2})(\d{2}|\d{3})$/);
    if (match) {
      return ['+', match[2], ' ', match[3], ' ', match[4], ' ', match[5], ' ', match[6]].join('');
    }
    return null;
  };

  const timeOut = () => {
    if (timer.minutes === 0 && timer.seconds === 0) {
      return true;
    }
    return false;
  };

  const showTimer = () => {
    return (
      <span className="simple-text">
        {timer.minutes < 1 ? '' : timer.minutes + ':'}
        {timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}
      </span>
    );
  };

  const sendNewCodeWarningBox = (
    <span>
      {translate('countdownTimer.messageText6')}&nbsp;
      <span className="simple-text">{formatPhoneNumber(phoneNumber)}.</span>
      &nbsp;{translate('countdownTimer.messageText7')}&nbsp;
      {showTimer()}
    </span>
  );

  const defaultWarningBox = (
    <span>
      {translate('countdownTimer.messageText4')}
      &nbsp;{showTimer()}&nbsp;
      {translate('countdownTimer.messageText5')}
    </span>
  );

  const infoBoxBody = (
    <p>
      {translate('countdownTimer.messageText1')}&nbsp;
      <span className={window.isGreenLogin ? 'bring-text' : 'posten-text'}>{formatPhoneNumber(phoneNumber)}</span>&nbsp;
      {translate('countdownTimer.messageText2')}&nbsp;
      <span className={window.isGreenLogin ? 'bring-text' : 'posten-text'}>
        {translate('countdownTimer.15minutes')}.
      </span>
      &nbsp;
      {translate('countdownTimer.messageText3')}
    </p>
  );

  return (
    <div className="hw-block hw-block--mt-medium-4">
      <div className="hw-info-box">
        <div className="hw-info-box__body">
          {infoBoxBody}
          {showResendLink && (
            <a className="hw-link simple-text" onClick={() => sendVerificationCode()}>
              {translate('countdownTimer.codeLink')}
            </a>
          )}
          {!timeOut() && (
            <div className="hw-block--mt-medium-2">
              <div className="hw-warning-box">
                <div className="hw-warning-box__body">{sendNewCode ? sendNewCodeWarningBox : defaultWarningBox}</div>
              </div>
            </div>
          )}
          {errorObj.error && (
            <div className="hw-block--mt-medium-2">
              <ErrorInfo error={errorObj.error} errorMessage={errorObj.errorMessage} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
