import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { loginUserWithPasswordAndSmsCode } from '../../../data/api';
import { required } from '../../../utils/ValidationsUtils';
import AccessibleH1Heading from '../../components/AccessibleH1Heading';
import CountdownTimer from '../../components/CountdownTimer';
import ErrorInfo from '../../components/ErrorInfo';
import InputType from '../../components/InputType';
import { handlePostLogin, PhoneNumberPassword, VALIDATE_SMS_CODE } from './Login';
import { ReactFinalFormWebOtpSubmitter } from '../../components/useWebOTP';

type ValidateSmsCodeProps = VALIDATE_SMS_CODE & {
  phoneNumberPassword: PhoneNumberPassword;
  authUrlKey: string;
};
const ValidateSmsCode = ({ phoneNumberPassword, authUrlKey }: ValidateSmsCodeProps) => {
  const { t: translate } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '', errorCode: '' });

  type FormValues = { verificationCode: string };
  const onSubmit = async ({ verificationCode }: FormValues) => {
    setSubmitting(true);
    const payload = {
      ...phoneNumberPassword,
      verificationCode,
    };
    const response = await loginUserWithPasswordAndSmsCode(payload);
    if (response.status !== 200) {
      setSubmitting(false);
      setErrorObj({ error: true, errorMessage: response.message, errorCode: response.errorCode });
      return;
    } else {
      handlePostLogin({ authUrlKey });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <ReactFinalFormWebOtpSubmitter otpFieldName="verificationCode" autoSubmit={false} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
            <AccessibleH1Heading>{translate('login.twoFactorVerificationPage')}</AccessibleH1Heading>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              {/* HACK: Revert the margin-top from the CountdownTimer component */}
              <div style={{ marginTop: -40 }}>
                <CountdownTimer
                  phoneNumber={phoneNumberPassword.phoneNumber}
                  password={phoneNumberPassword.password}
                  flow="LoginUser"
                />
              </div>

              <label className="hw-label">
                {translate('registerUser.codeFromSms')}
                <Field
                  type="text"
                  component={InputType}
                  validate={required}
                  name="verificationCode"
                  autoComplete="one-time-code"
                  inputMode="numeric"
                  pattern="\d{6}"
                />
              </label>
            </div>

            <ErrorInfo error={errorObj.error} errorMessage={errorObj.errorMessage} />

            <button type="submit" disabled={isSubmitting} className="hw-button hw-button--primary hw-button--medium">
              {translate('login.confirm')}
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default ValidateSmsCode;
