// @ts-check
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import EnterUserDetails from './EnterUserDetails';
import PostRegisterUser from './PostRegisterUser';
import ValidateSmsCode from './ValidateSmsCode';

/**
 * RegisterUser
 */
export interface FromPasswordReset {
  phoneNumber: string;
  verificationCode: string;
}

export interface UserDetails {
  phoneNumber: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  password: string;
  userConsent: boolean;
}

export interface ENTER_USER_DETAILS {
  name: 'ENTER_USER_DETAILS';
}
export interface VALIDATE_SMS_CODE {
  name: 'VALIDATE_SMS_CODE';
  userDetails: UserDetails;
}
export interface POST_REGISTER_USER {
  name: 'POST_REGISTER_USER';
}
export type Step = ENTER_USER_DETAILS | VALIDATE_SMS_CODE | POST_REGISTER_USER;
export type SetStepFunction = (step: Step) => void;

const RegisterUser = () => {
  const params = useParams();
  const locationState =
      (useLocation().state as { authUrlKey?: string; fromPasswordReset?: FromPasswordReset } | undefined) ?? {};
  const fromPasswordReset = locationState.fromPasswordReset;
  const authUrlKey = params.authUrlKey || locationState.authUrlKey;

  const [step, setStep] = useState<Step>({ name: 'ENTER_USER_DETAILS' });

  return (
    <div className="hw-container hw-container--slim">
      <div className="hw-block hw-block--pb-large hw-block--pt-small-3 hw-block--mb-even-larger-mobile">
        <div className="hw-grid">
          <div className="hw-grid__item hw-one-whole">
            {step.name === 'ENTER_USER_DETAILS' && (
              <EnterUserDetails
                {...step}
                authUrlKey={authUrlKey}
                fromPasswordReset={fromPasswordReset}
                setStep={setStep}
              />
            )}
            {step.name === 'VALIDATE_SMS_CODE' && <ValidateSmsCode {...step} setStep={setStep} />}
            {step.name === 'POST_REGISTER_USER' && <PostRegisterUser {...step} authUrlKey={authUrlKey} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterUser;
