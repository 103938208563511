export const loadScript = (src: string, id: string, callback?: (this: GlobalEventHandlers, ev: Event) => any) => {
  let element = document.getElementById(id);
  element?.parentNode?.removeChild(element);

  let scriptTag = document.createElement('script');
  scriptTag.setAttribute('src', src);
  scriptTag.setAttribute('id', id);
  scriptTag.onload = callback ?? null;
  scriptTag.async = true;
  document.body.appendChild(scriptTag);
};
