const messages = {
  en: {
    validMobileNumber: 'Invalid Mobile number.',
    required: 'Field is required',
    requiredWithLabel: (name: string) => `${name} is required.`,
    mobileNumberRequired: 'Mobile number is required.',
    passwordRequired: 'Password is required.',
    firstNameMinLength: 'First name must be longer than 2 characters.',
    firstNameMaxLength: 'First name must be shorter than 60 characters.',
    lastNameMinLength: 'Last name must be longer than 2 characters.',
    lastNameMaxLength: 'Last name must be shorter than 60 characters.',
    validateEmail: 'E-mail must be entered correctly.',
    minimumPasswordLength: 'Password must be at least 8 characters.',
    commonPassword: 'Weak password. Choose another password for security reasons.',
    noLowercase: 'Password must contain a lower case letter.',
    noUpperCase: 'The password must contain a uppercase letter.',
    noNumberCase: 'The password must contain a number.',
    confirmPassword: 'The passwords you entered are different.',
    termsAndConditions: 'You must accept the terms before you can continue.',
    selectContactInformation: 'You must select contact information before proceeding.',
  },
  no: {
    validMobileNumber: 'Nummeret er ugyldig.',
    required: 'Feltet må fylles ut.',
    requiredWithLabel: (name: string) => `${name} må fylles ut.`,
    mobileNumberRequired: 'Mobilnummer er påkrevd.',
    passwordRequired: 'Passord er påkrevd.',
    firstNameMinLength: 'Fornavn må være minst 2 tegn.',
    firstNameMaxLength: 'Fornavn kan maks være 60 tegn.',
    lastNameMinLength: 'Etternavn må være minst 2 tegn.',
    lastNameMaxLength: 'Etternavn kan maks være 60 tegn.',
    validateEmail: 'E-post må fylles ut riktig.',
    minimumPasswordLength: 'Passordet må være minst 8 tegn.',
    commonPassword: 'Passordet er for svakt. For sikkerhets skyld velg et annet passord.',
    noLowercase: 'Passordet må inneholde en liten bokstav.',
    noUpperCase: 'Passordet må inneholde en stor bokstav.',
    noNumberCase: 'Passordet må inneholde et tall.',
    confirmPassword: 'Passordene du skrev er ulike.',
    termsAndConditions: 'Du må godta vilkårene før du kan fortsette.',
    selectContactInformation: 'Du må velge kontaktinformasjon før du kan gå videre.',
  },
  da: {
    validMobileNumber: 'Ugyldigt mobilnummer',
    required: 'Feltet er obligatorisk',
    requiredWithLabel: (name: string) => `${name} er påkrævet`,
    mobileNumberRequired: 'Mobilnummer er obligatorisk',
    passwordRequired: 'Password er påkrævet',
    firstNameMinLength: 'Fornavnet skal være længere end 2 tegn',
    firstNameMaxLength: 'Fornavnet skal være kortere end 60 tegn.',
    lastNameMinLength: 'Efternavnet skal være længere end 2 tegn.',
    lastNameMaxLength: 'Efternavnet skal være kortere end 60 tegn.',
    validateEmail: 'E-mailadressen skal udfyldes korrekt.',
    minimumPasswordLength: 'Passwordet skal være mindst 8 tegn.',
    commonPassword: 'Svagt password. Vælg et andet password af sikkerhedshensyn.',
    noLowercase: 'Passwordet skal indeholde et lille bogstav',
    noUpperCase: 'Passwordet skal indeholde et stort bogstav',
    noNumberCase: 'Passwordet skal indeholde et tal',
    confirmPassword: 'De to passwords du angav er forskellige',
    termsAndConditions: 'Du skal acceptere vilkårene for brug inden du kan fortsætte',
    selectContactInformation: 'Du skal vælge kontaktoplysninger inden du kan fortsætt',
  },
  sv: {
    validMobileNumber: 'Ogiltigt mobilnummer.',
    required: 'Fältet är obligatoriskt',
    requiredWithLabel: (name: string) => `${name} krävs`,
    mobileNumberRequired: 'Mobilnummer är obligatoriskt.',
    passwordRequired: 'Lösenord krävs',
    firstNameMinLength: 'Förnamnet måste vara längre än 2 tecken.',
    firstNameMaxLength: 'Förnamnet måste vara kortare än 60 tecken.',
    lastNameMinLength: 'Efternamnet måste vara längre än 2 tecken.',
    lastNameMaxLength: 'Efternamnet måste vara kortare än 60 tecken.',
    validateEmail: 'Mejladress måste fyllas i korrekt.',
    minimumPasswordLength: 'Lösenordet måste vara minst 8 tecken.',
    commonPassword: 'Svagt lösenord. Välj ett annat lösenord av säkerhetsskäl.',
    noLowercase: 'Lösenordet måste innehålla en liten bokstav.',
    noUpperCase: 'Lösenordet måste innehålla en stor bokstav.',
    noNumberCase: 'Lösenordet måste innehålla en siffra.',
    confirmPassword: 'De lösenord du angav är olika.',
    termsAndConditions: 'Du måste acceptera villkoren innan du kan fortsätta.',
    selectContactInformation: 'Du måste välja kontaktinformation innan du fortsätter.',
  },
} as const;

export default messages;
