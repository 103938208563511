import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../home/locales/en.json';
import sv from '../home/locales/sv.json';
import no from '../home/locales/no.json';
import da from '../home/locales/da.json';
import config from '../config';

export const resources = {
  en,
  sv,
  da,
  no,
} as const;
export const defaultNS = 'translations';

i18n.use(initReactI18next).init({
  resources,
  lng: config.lang,
  fallbackLng: 'no',
  debug: true,
  ns: [defaultNS],
  defaultNS,
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
});

export default i18n;
