import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getBankIdUrls } from '../../data/api';
import ErrorInfo from '../components/ErrorInfo';
import Loading from '../components/Loading';

const Level4AuthenticationPage = () => {
  const { t: translate } = useTranslation();
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '' });
  const [authenticateUrl, setAuthenticateUrl] = useState({
    bankIdUrl: '',
    bankIdOnMobilUrl: '',
  });
  const [iFrameSrc, setIFrameSrc] = useState('');
  const [loadBankId, setBankId] = useState(true);
  const [loading, setLoading] = useState(false);

  const loadBankIdUrl = () => {
    setIFrameSrc(authenticateUrl.bankIdUrl);
    setBankId(false);
    setLoading(true);
  };

  const loadBankIdOnMobileUrl = () => {
    setIFrameSrc(authenticateUrl.bankIdOnMobilUrl);
    setBankId(true);
    setLoading(true);
  };

  const hideSpinner = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    getBankIdUrls().then((response) => {
      if (response.status === 200) {
        setAuthenticateUrl({
          bankIdUrl: response.data.bankIdUrl,
          bankIdOnMobilUrl: response.data.bankIdOnMobilUrl,
        });
        setIFrameSrc(response.data.bankIdOnMobilUrl);
        setLoading(true);
      } else {
        setErrorObj({ error: true, errorMessage: response.message });
      }
    });
  }, []);

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <div className="hw-block--mb-large hw-block--mt-large">
        <div className="hw-container hw-container--slim hw-background-color-white">
          <div className="hw-block hw-block--pb-large hw-block--pt-large">
            <div className="hw-grid">
              <div className="hw-grid__item hw-one-whole">
                <div>
                  <h1 className="hw-block--pb">{translate('bankidauthentication.heading')}</h1>
                  <p>{translate('bankidauthentication.text')}</p>
                </div>
                <div className="hw-block hw-block--mb hw-block--mt-small">
                  <div className="hw-block--pb-smallest">
                    <div className="hw-tab">
                      <button
                        className={`hw-tab__item ${loadBankId && 'hw-tab__item--selected'}`}
                        onClick={() => loadBankIdOnMobileUrl()}>
                        {translate('bankidauthentication.bankidmobile')}
                      </button>
                      <button
                        className={`hw-tab__item ${!loadBankId && 'hw-tab__item--selected'}`}
                        onClick={() => loadBankIdUrl()}>
                        {translate('bankidauthentication.bankid')}
                      </button>
                    </div>
                  </div>
                  <div>
                    <iframe
                      title="Bank id frame loading window"
                      src={iFrameSrc}
                      width="100%"
                      height="290"
                      frameBorder="0"
                      scrolling="no"
                      onLoad={() => hideSpinner()}></iframe>
                    <ErrorInfo error={errorObj.error} errorMessage={errorObj.errorMessage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Level4AuthenticationPage;
