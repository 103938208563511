import { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  getClientDetails,
  getUserProfile,
  updateLastLoginAndActivityDate,
  validatePhoneNumberPasswordAndCreateSmsCode,
} from '../../../data/api';
import { getCountryList } from '../../../utils/country';
import {
  trimValue,
  composeValidators,
  mobileNumberRequired,
  validMobileNumberWithISDCode,
  passwordRequired,
} from '../../../utils/ValidationsUtils';
import ErrorInfo from '../../components/ErrorInfo';
import PasswordInputField from '../../components/PasswordInput/PasswordInputField';
import PhoneInputType from '../../components/PhoneInputType';
import VippsButton from '../../components/VippsButton/VippsButton';
import { ENTER_PHONE_NUMBER_PASSWORD, handlePostLogin, SetStepFunction } from './Login';

type EnterPhoneNumberPasswordProps = ENTER_PHONE_NUMBER_PASSWORD & {
  authUrlKey: string;
  setStep: SetStepFunction;
};
export type PhoneNumberPassword = {
  phoneNumber: string;
  password: string;
};
const EnterPhoneNumberPassword = ({ authUrlKey, setStep }: EnterPhoneNumberPasswordProps) => {
  const { t: translate } = useTranslation();
  const [loginMessage, setLoginMessage] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorObj, setErrorObj] = useState({ error: false, errorMessage: '', errorCode: '' });
  const [selectedCountry, setSelectedCountry] = useState(() => getCountryList()[0].value);

  /**
   * When the user first hits the login page we need to do some checks
   * Is the user already logged in? If so, redirect them
   * Was the user sent to login page from somewhere else? Show them the reason
   */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('error') === 'login_failed') {
      setErrorObj({ error: true, errorMessage: translate('login.loginError'), errorCode: '' });
      window.history.pushState({}, document.title, window.location.href.split('?')[0]);
      return;
    }
    if (params.get('error') === 'outdated_app_version') {
      setErrorObj({ error: true, errorMessage: translate('login.outdatedVippsAppVersion'), errorCode: '' });
      window.history.pushState({}, document.title, window.location.href.split('?')[0]);
      return;
    }

    getUserProfile().then((response) => {
      if (response.errorCode && response.errorCode === 'not_logged_in') {
        if (authUrlKey.length > 0) {
          getClientDetails({ authUrlKey: authUrlKey }).then((response) => {
            if (response.status === 200 && response.data.name.length > 0) {
              setErrorObj({ error: false, errorMessage: '', errorCode: '' });
              setLoginMessage(response.data.description);
            } else {
              setErrorObj({ error: true, errorMessage: response.message, errorCode: response.errorCode });
            }
          });
        }
      } else {
        updateLastLoginAndActivityDate().then((response) => {
          if (response.status !== 200) {
            setErrorObj({ error: true, errorMessage: response.message, errorCode: response.errorCode });
          } else {
            handlePostLogin({ authUrlKey });
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type FormValues = PhoneNumberPassword;
  const onSubmit = async (rawPhoneNumberPassword: FormValues) => {
    setSubmitting(true);

    const phoneNumber = trimValue(selectedCountry + rawPhoneNumberPassword.phoneNumber);
    const phoneNumberPassword = { ...rawPhoneNumberPassword, phoneNumber };

    const response = await validatePhoneNumberPasswordAndCreateSmsCode(phoneNumberPassword);
    if (response.status === 201 || response.errorCode === 'recently_sent_try_again_later') {
      setStep({ name: 'VALIDATE_SMS_CODE', phoneNumberPassword });
    } else {
      setSubmitting(false);
      setErrorObj({ error: true, errorMessage: response.message, errorCode: response.errorCode });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
            <h1>{translate('login.heading')}</h1>

            {loginMessage && <p className="hw-text-lead">{loginMessage}</p>}

            {!window.isGreenLogin && (
              <VippsButton variant="login" authUrlKey={authUrlKey} className="hw-button--medium hw-button--full" />
            )}

            {!window.isGreenLogin && <DividerWithText translate={translate} />}

            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <Field
                label={translate('registerUser.mobileNumber')}
                name="phoneNumber"
                id="phoneNumber"
                component={PhoneInputType}
                countryOptions={getCountryList()}
                onCountrySelect={setSelectedCountry}
                validate={composeValidators(mobileNumberRequired, validMobileNumberWithISDCode)}
              />

              <label className="hw-label">
                {translate('login.password')}
                <Field
                  component={PasswordInputField}
                  validate={passwordRequired}
                  name="password"
                  id="password"
                  autoComplete="current-password"
                />
              </label>

              <Link className="hw-color-primary hw-text-bold" to="/password/reset" state={{ authUrlKey }}>
                {translate('login.forgotPassword')}
              </Link>
            </div>

            <ErrorInfo error={errorObj.error} errorMessage={errorObj.errorMessage} />

            <button type="submit" disabled={isSubmitting} className="hw-button hw-button--primary hw-button--medium">
              {translate('login.loginButton')}
            </button>

            <DividerWithText translate={translate} />

            <Link className="hw-button hw-button--outline-secondary" to="/register" state={{ authUrlKey }}>
              {translate('registerUser.createNewUser')}
            </Link>
          </div>
        </form>
      )}
    />
  );
};

export default EnterPhoneNumberPassword;

// Components
const DividerWithText = ({ translate }) => {
  return <div className="divider-with-text">{translate('login.or')}</div>;
};
