import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  error: boolean;
  errorMessage: string;
  phoneNumberInUse?: boolean;
}
export const ErrorInfo = ({ error, errorMessage, phoneNumberInUse = false }: Props) => {
  const { t: translate } = useTranslation();
  if (error) {
    return (
      <div className="hw-error-summary" role="alert">
        <div className="hw-error-summary__title">{errorMessage}</div>
        {phoneNumberInUse && (
          <div className="hw-error-summary__contents">
            <div className="hw-block--mt-small">
              <Link to="/login" className="hw-link">
                {translate('login.heading')}
              </Link>
            </div>
            <div className="hw-block--mt-smallest">
              <Link to="/password/reset" className="hw-link">
                {translate('passwordReset.forgotPassword')}?
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default ErrorInfo;
