import React, { useId } from 'react';
import { shouldShowFieldError } from '../../utils/ValidationsUtils';

interface Props {
  input: any;
  meta: any;
  className?: string;
}
const InputType = ({ input, meta, className = '', ...rest }: Props) => {
  const showError = shouldShowFieldError(meta);
  const errorId = useId();
  return (
    <>
      <input
        aria-invalid={showError}
        aria-describedby={errorId}
        {...input}
        {...rest}
        className={`hw-input ${showError ? 'hw-input--error' : ''} ${className}`}
      />
      {showError && (
        <span id={errorId} className="hw-error">
          {meta.error}
        </span>
      )}
    </>
  );
};

export default InputType;
