import { useTranslation } from 'react-i18next';
import {
  ActionFunctionArgs,
  Form,
  json,
  LoaderFunctionArgs,
  useActionData,
  useLoaderData,
  useNavigation,
} from 'react-router-dom';
import config from '../../../config';
import { getVippsUserInfo, updateVippsUserInfo } from '../../../data/api';
import ErrorInfo from '../../components/ErrorInfo';

interface UserDetails {
  firstName: string;
  lastName: string;
  emailAddress: string;
}
interface LoaderData {
  uniqueId: string;
  emailAddress: string;
  oauthUrlKey: string;
  postenUserDetails: UserDetails;
  vippsUserDetails: UserDetails;
}
export async function loader({ params }: LoaderFunctionArgs) {
  const uniqueId = params.uniqueId;
  if (!uniqueId) {
    throw new Error('expected uniqueId');
  }
  const emailAddress = window.sessionStorage.getItem('emailAddress');
  if (!emailAddress) {
    throw new Error('expected emailAddress');
  }

  const { data } = await getVippsUserInfo({ uniqueId, emailAddress });

  const result: LoaderData = {
    uniqueId,
    emailAddress,
    oauthUrlKey: data.oauthUrlKey,
    postenUserDetails: {
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.emailAddress,
    },
    vippsUserDetails: {
      firstName: data.vippsUserFirstName,
      lastName: data.vippsUserLastName,
      emailAddress: data.vippsUserEmailAddress,
    },
  };
  return json(result);
}

type ActionData = string | undefined;
export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const vippsOrPostenUserDetails = formData.get('vipps-or-posten-user-details') as 'posten' | 'vipps';
  const isVippsInfoSelected = vippsOrPostenUserDetails === 'vipps';

  const uniqueId = formData.get('uniqueId') as string;
  const emailAddress = formData.get('emailAddress') as string;
  const oauthUrlKey = formData.get('oauthUrlKey') as string;

  const response = await updateVippsUserInfo({ isVippsInfoSelected, uniqueId, emailAddress });
  if (response.status === 200) {
    if (oauthUrlKey.length > 0) {
      window.location.href = config.contextPath + '/api/oauth/authorizations/authorize/' + oauthUrlKey;
    } else {
      window.location.href = config.contextPath + '/profile';
    }
    return null;
  }

  const errorMessage: ActionData = response.message;
  return json(errorMessage);
}

export function Component() {
  const { postenUserDetails, vippsUserDetails, oauthUrlKey, uniqueId, emailAddress } = useLoaderData() as LoaderData;
  const navigation = useNavigation();
  const { t: translate } = useTranslation();
  const errorMessage = useActionData() as ActionData;

  return (
    <div className="hw-container hw-container--slim">
      <div className="hw-block hw-block--pb-large hw-block--pt-small-3 hw-block--mb-even-larger-mobile">
        <div className="hw-grid">
          <div className="hw-grid__item hw-one-whole">
            <Form method="post">
              <h1>{translate('vippsOrPostenUserDetails.validateHeading')}</h1>

              <div className="hw-block--mt-medium-3" />

              <input name="oauthUrlKey" value={oauthUrlKey} hidden readOnly />
              <input name="uniqueId" value={uniqueId} hidden readOnly />
              <input name="emailAddress" value={emailAddress} hidden readOnly />

              <fieldset className="hw-fieldset">
                <legend className="hw-fieldset__legend">{translate('login.infoToUseForLogin')}</legend>
                <div className="hw-fieldset__content" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label className="hw-radio-button hw-radio-button--bounding" style={{ marginRight: 0 }}>
                    <input type="radio" name="vipps-or-posten-user-details" value="vipps" required />
                    <span className="hw-radio-button__label">
                      {vippsUserDetails.firstName} {vippsUserDetails.lastName}
                      <br />
                      <span>{vippsUserDetails.emailAddress}</span>
                    </span>
                    <i className="hw-radio-button__indicator"></i>
                  </label>
                  <label className="hw-radio-button hw-radio-button--bounding" style={{ marginRight: 0 }}>
                    <input type="radio" name="vipps-or-posten-user-details" value="posten" required />
                    <span className="hw-radio-button__label">
                      {postenUserDetails.firstName} {postenUserDetails.lastName}
                      <br />
                      <span>{postenUserDetails.emailAddress}</span>
                    </span>
                    <i className="hw-radio-button__indicator"></i>
                  </label>
                </div>
              </fieldset>

              <div className="hw-block--mt-small-4" />
              <ErrorInfo error={Boolean(errorMessage)} errorMessage={errorMessage!} />
              <div className="hw-block--mt-small-3" />

              <div className="hw-block--mt-medium-3" />
              <button
                type="submit"
                disabled={navigation.state === 'submitting'}
                className="hw-button hw-button--primary hw-button--full hw-button--medium">
                {translate('vippsOrPostenUserDetails.confirm')}
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
